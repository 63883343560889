import { $, $$, type Readable, type ViewContext } from "@manyducks.co/dolla";
import Quill, { type RangeStatic } from "quill";
import scrollStyles from "@styles/ScrollBar.module.css";
import CodeIcon from "@views/@icons/Code";
import { HoverMenu } from "@views/HoverMenu";
import { type Awareness } from "y-protocols/awareness";
import { QuillBinding } from "y-quill";
import * as Y from "yjs";
import markdownStyles from "./Markdown.module.css";
import styles from "./TextEditor.module.css";
import { QuackLinkBlot } from "./blots/QuackLinkBlot";
import AttachmentIcon from "./icons/Attachment";
import BoldIcon from "./icons/Bold";
import Heading1Icon from "./icons/Heading1";
import Heading2Icon from "./icons/Heading2";
import Heading3Icon from "./icons/Heading3";
import ItalicIcon from "./icons/Italic";
import LinkIcon from "./icons/Link";
import ListOrderedIcon from "./icons/ListOrdered";
import ListUnorderedIcon from "./icons/ListUnordered";
import QuoteIcon from "./icons/Quote";
import StrikeIcon from "./icons/Strike";
import IndentIncreaseIcon from "./icons/IndentIncrease";
import IndentDecreaseIcon from "./icons/IndentDecrease";

// NOTE: Quill modules are registered in browser/app.ts

interface TextEditorProps {
  autoFocus?: boolean;

  accentColor?: string | Readable<string>;

  onFocus?: () => void;
  onBlur?: () => void;

  // Content is provided through a Y.Text field.
  content: Y.Text;
  awareness?: Awareness;

  readOnly?: boolean | Readable<boolean>;

  scrollable?: boolean | Readable<boolean>;
  // scrollContainerRef: Writable<any>;
}

export function TextEditor(props: TextEditorProps, ctx: ViewContext) {
  const { content, awareness } = props;
  // const { translate } = ctx.getStore(LanguageStore);
  const $$editorElement = $$<HTMLDivElement>();
  const $readOnly = $(props.readOnly);
  const $scrollable = $(props.scrollable);

  const toolbarId = ctx.uniqueId + "_toolbar";

  let editor: Quill;
  let binding: QuillBinding;

  const $$toolbarState = $$<{
    bold: boolean;
    italic: boolean;
    strike: boolean;
    blockquote: boolean;
    indent: number;
    "code-block": boolean;
    list: "ordered" | "bullet" | null;
    header: number | null;
  }>({
    bold: false,
    italic: false,
    strike: false,
    blockquote: false,
    indent: 0,
    "code-block": false,
    list: null,
    header: null,
  });

  function updateToolbarState() {
    if (!editor.hasFocus()) return; // Fix: Steals focus from active element when getFormat is called.

    const format = editor.getFormat();

    $$toolbarState.set({
      bold: format.bold ?? false,
      italic: format.italic ?? false,
      strike: format.strike ?? false,
      blockquote: format.blockquote ?? false,
      indent: format.indent ?? 0,
      "code-block": format["code-block"] ?? false,
      list: format.list ?? null,
      header: format.header ?? null,
    });
  }

  ctx.onConnected(() => {
    editor = new Quill($$editorElement.get()!, {
      readOnly: $readOnly.get(),
      formats: [
        "bold",
        "header",
        "italic",
        "strike",
        "link",
        "list",
        "blockquote",
        "indent",
        "image",
        "indent",
        "code-block",
        "quackLink",
      ],
      modules: {
        cursors: true,
        magicUrl: true,
        history: {
          userOnly: true, // Don't undo changes from remote users.
        },
      },
      // scrollingContainer: props.scrollContainerRef.get(),
    });

    binding = new QuillBinding(content, editor, awareness);

    // Set link blot context object so it can display live link data and open cards.
    (editor.scroll as any).emitter.on("quack-link-attached", (blot: QuackLinkBlot) => {
      blot.setContext(ctx, props.cardId);
    });

    if (props.onFocus) {
      editor.root.addEventListener("focus", () => {
        props.onFocus!();
      });
    }

    if (props.onBlur) {
      editor.root.addEventListener("blur", () => {
        props.onBlur!();
      });
    }

    editor.on("selection-change", updateToolbarState);
    editor.on("text-change", updateToolbarState);

    if (props.autoFocus) {
      editor.setSelection(editor.getLength(), 0, "silent");
    }
  });

  ctx.onDisconnected(() => {
    binding?.destroy();
  });

  ctx.observe($readOnly, (readOnly) => {
    if (readOnly) {
      editor.disable();
    } else {
      editor.enable();
    }
  });

  return (
    <div
      class={{
        [styles.container]: true,
        [styles.scrollable]: $scrollable,
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div class={[styles.editor, markdownStyles.markdown]} ref={$$editorElement}></div>

      <div id={toolbarId} class={[styles.toolbar, { [styles.hidden]: $readOnly }]}>
        <div class={styles.toolbarGroup}>
          <button
            class={[styles.toolbarButton, { [styles.active]: $($$toolbarState, (s) => s.bold) }]}
            type="button"
            onClick={(e) => {
              e.preventDefault();
              const format = editor.getFormat();
              if (format.bold) {
                editor.format("bold", false, "user");
              } else {
                editor.format("bold", true, "user");
              }
            }}
          >
            <BoldIcon />
          </button>
          <button
            type="button"
            class={[styles.toolbarButton, { [styles.active]: $($$toolbarState, (s) => s.italic) }]}
            onClick={(e) => {
              e.preventDefault();
              const format = editor.getFormat();
              if (format.italic) {
                editor.format("italic", false, "user");
              } else {
                editor.format("italic", true, "user");
              }
            }}
          >
            <ItalicIcon />
          </button>
          <button
            type="button"
            class={[styles.toolbarButton, { [styles.active]: $($$toolbarState, (s) => s.strike) }]}
            onClick={(e) => {
              e.preventDefault();
              const format = editor.getFormat();
              if (format.strike) {
                editor.format("strike", false, "user");
              } else {
                editor.format("strike", true, "user");
              }
            }}
          >
            <StrikeIcon />
          </button>

          <div class={styles.toolbarDivider} />

          <button
            type="button"
            class={[styles.toolbarButton, { [styles.active]: $($$toolbarState, (s) => s.header === 1) }]}
            onClick={(e) => {
              e.preventDefault();
              const format = editor.getFormat();
              if (format.header === 1) {
                editor.format("header", null, "user");
              } else {
                editor.format("header", 1, "user");
              }
            }}
          >
            <Heading1Icon />
          </button>
          <button
            type="button"
            class={[styles.toolbarButton, { [styles.active]: $($$toolbarState, (s) => s.header === 2) }]}
            onClick={(e) => {
              e.preventDefault();
              const format = editor.getFormat();
              if (format.header === 2) {
                editor.format("header", null, "user");
              } else {
                editor.format("header", 2, "user");
              }
            }}
          >
            <Heading2Icon />
          </button>
          <button
            type="button"
            class={[styles.toolbarButton, { [styles.active]: $($$toolbarState, (s) => s.header === 3) }]}
            onClick={(e) => {
              e.preventDefault();
              const format = editor.getFormat();
              if (format.header === 3) {
                editor.format("header", null, "user");
              } else {
                editor.format("header", 3, "user");
              }
            }}
          >
            <Heading3Icon />
          </button>

          <div class={styles.toolbarDivider} />

          <button
            type="button"
            class={[styles.toolbarButton, { [styles.active]: $($$toolbarState, (s) => s.list === "bullet") }]}
            onClick={(e) => {
              e.preventDefault();
              const format = editor.getFormat();
              if (format.list === "bullet") {
                editor.format("list", null, "user");
              } else {
                editor.format("list", "bullet", "user");
              }
            }}
          >
            <ListUnorderedIcon />
          </button>
          <button
            type="button"
            class={[
              styles.toolbarButton,
              { [styles.active]: $($$toolbarState, (s) => s.list === "ordered") },
            ]}
            onClick={(e) => {
              e.preventDefault();
              const format = editor.getFormat();
              if (format.list === "ordered") {
                editor.format("list", null, "user");
              } else {
                editor.format("list", "ordered", "user");
              }
            }}
          >
            <ListOrderedIcon />
          </button>
          <button
            type="button"
            class={[styles.toolbarButton, { [styles.active]: $($$toolbarState, (s) => s.blockquote) }]}
            onClick={(e) => {
              e.preventDefault();
              const format = editor.getFormat();
              if (format.blockquote) {
                editor.format("blockquote", null, "user");
              } else {
                editor.format("blockquote", true, "user");
              }
            }}
          >
            <QuoteIcon />
          </button>

          <div class={styles.toolbarDivider} />

          <button
            type="button"
            class={styles.toolbarButton}
            disabled={$($$toolbarState, (s) => s.indent === 0)}
            onClick={(e) => {
              e.preventDefault();
              const format = editor.getFormat();
              console.log(format);
              editor.format("indent", ($$toolbarState.get().indent - 1) % 3, "user");
            }}
          >
            <IndentDecreaseIcon />
          </button>

          <button
            type="button"
            class={styles.toolbarButton}
            disabled={$($$toolbarState, (s) => s.indent >= 2)}
            onClick={(e) => {
              e.preventDefault();
              editor.format("indent", ($$toolbarState.get().indent + 1) % 3, "user");
            }}
          >
            <IndentIncreaseIcon />
          </button>

          <div class={styles.toolbarDivider} />

          <button
            type="button"
            class={[styles.toolbarButton, { [styles.active]: $($$toolbarState, (s) => s["code-block"]) }]}
            onClick={(e) => {
              e.preventDefault();
              const format = editor.getFormat();
              if (format["code-block"]) {
                editor.format("code-block", null, "user");
              } else {
                editor.format("code-block", true, "user");
              }
            }}
          >
            <CodeIcon />
          </button>
        </div>
      </div>
    </div>
  );
}
