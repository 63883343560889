import {
  $,
  $$,
  DialogStore,
  HTTPStore,
  LanguageStore,
  RouterStore,
  cond,
  repeat,
  type Readable,
  type ViewContext,
} from "@manyducks.co/dolla";
import { AuthStore } from "@stores/AuthStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { ThemeStore } from "@stores/ThemeStore";
import { ConfirmDelete } from "@views/@dialogs";
import FolderIcon from "@views/@icons/Folder";
import LogOutIcon from "@views/@icons/LogOut";
import Mail from "@views/@icons/Mail";
import Plus from "@views/@icons/Plus";
import ProjectAdmin from "@views/@icons/ProjectAdmin";
import ProjectCollaborator from "@views/@icons/ProjectCollaborator";
import ProjectOwner from "@views/@icons/ProjectOwner";
import ProjectViewer from "@views/@icons/ProjectViewer";
import Time from "@views/@icons/Time";
import Trash from "@views/@icons/Trash";
import { Badge } from "@views/Badge";
import { Button } from "@views/Button";
import { CardContent, CardToolbar } from "@views/Card";
import { CollapsibleListSection } from "@views/CollapsibleListSection";
import { MoreMenu } from "@views/MoreMenu";
import { Switch } from "@views/Switch";
import { ToolBar, ToolBarGroup } from "@views/ToolBar";
import { UserAvatar } from "@views/UserAvatar";
import { Project, User } from "schemas";
import { EditProject } from "../EditProject/EditProject";
import { LeaveProjectDialog } from "../LeaveProjectDialog/LeaveProjectDialog";
import styles from "./Details.module.css";
import { InviteDialog } from "./InviteDialog/InviteDialog";
import { Confirm } from "@views/@dialogs/Confirm/Confirm";

interface DetailsProps {
  $project: Readable<Project>;
}

interface ProjectUser extends User {
  role: Project["users"][0]["role"];
  joinedAt: string;
}

export function Details(props: DetailsProps, ctx: ViewContext) {
  const auth = ctx.getStore(AuthStore);
  const projects = ctx.getStore(ProjectsStore);
  const dialog = ctx.getStore(DialogStore);
  const http = ctx.getStore(HTTPStore);
  const router = ctx.getStore(RouterStore);
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);

  const $$moreMenuIsOpen = $$(false);
  const $$isEditing = $$(false);

  const $project = props.$project;
  const $userRole = $(auth.$me, $project, (me, project) => {
    if (!me || !project) return null;
    return project.users.find((u) => u.id === me.id)?.role ?? null;
  });

  const $isOwner = $($project, auth.$me, (project, me) => project?.ownerId === me?.id);
  const $isAdmin = $(
    $project,
    auth.$me,
    (project, me) =>
      project != null && me != null && !!project.users.find((u) => u.id === me.id && u.role === "admin"),
  );

  ctx.observe($isOwner, $isAdmin, $userRole, (isOwner, isAdmin, userRole) => {
    ctx.log({ isOwner, isAdmin, userRole });
  });

  const $users: Readable<(ProjectUser & User)[]> = $($project, auth.$users, (project, users) => {
    if (!project || !users) return [];

    return project.users.map((u) => {
      const user = users.find((user) => user.id === u.id)!;
      return { ...user, ...u };
    });
  });
  const $invites = $($project, (project) => {
    return project?.invites ?? [];
  });
  const $owners = $($project, $users, (project, users) => [users.find((u) => u.id === project.ownerId)!]);
  const $admins = $($project, $users, (project, users) =>
    users.filter((u) => u.role === "admin" && u.id !== project.ownerId),
  );
  const $collaborators = $($users, (users) => users.filter((u) => u.role === "collaborator"));

  async function sendInvite(email: string, role: "viewer" | "collaborator" | "admin") {
    const projectId = $project.get().id;
    try {
      await http.post(`/api/projects/${projectId}/invites`, {
        body: { email, role },
      });
    } catch (err: any) {
      if (err.response) {
        const res = err.response;
        ctx.error(res);
      } else {
        throw err;
      }
    }
  }

  return (
    <>
      <CardContent>
        <div class={styles.content}>
          <div class={styles.section}>
            <CollapsibleListSection title={translate("workspace.project.roles.owner.title")}>
              <UsersList $users={$owners} />
            </CollapsibleListSection>
          </div>

          <div class={styles.section}>
            <CollapsibleListSection
              title={translate("workspace.project.roles.admin.title")}
              itemCount={$($admins, (x) => x.length)}
              controls={[
                {
                  id: "add-user",
                  title: "Add",
                  icon: <Plus />,
                  variant: "accent",
                  hidden: $($isAdmin, (isAdmin) => !isAdmin),
                  onClick: (e) => {
                    const project = $project.get();

                    dialog.open(InviteDialog, {
                      project,
                      onSubmit(data) {
                        return sendInvite(data.email, data.role);
                      },
                      onCancel() {},
                    });
                  },
                },
              ]}
            >
              <UsersList $users={$admins} />
            </CollapsibleListSection>
          </div>

          <div class={styles.section}>
            <CollapsibleListSection
              title={translate("workspace.project.roles.collaborator.title")}
              itemCount={$($collaborators, (x) => x.length)}
              controls={[
                {
                  id: "add-user",
                  title: "Add",
                  icon: <Plus />,
                  variant: "accent",
                  hidden: $($isAdmin, (isAdmin) => !isAdmin),
                  onClick: (e) => {
                    const project = $project.get();

                    dialog.open(InviteDialog, {
                      project,
                      onSubmit(data) {
                        return sendInvite(data.email, data.role);
                      },
                      onCancel() {},
                    });
                  },
                },
              ]}
            >
              <UsersList $users={$collaborators} />
            </CollapsibleListSection>
          </div>

          {cond(
            $($invites, (invites) => invites.length > 0),
            <CollapsibleListSection
              title={translate("workspace.project.overview.invites.title")}
              itemCount={$($invites, (invites) => invites.length)}
            >
              <ul class={styles.userList}>
                {repeat(
                  $invites,
                  (invite) => invite.email,
                  ($invite) => {
                    const $email = $($invite, (i) => i.email);
                    const $role = $($invite, (i) => i.role);
                    const $timestamp = $($invite, (i) => i.createdAt);

                    return (
                      <li class={styles.inviteListItem}>
                        <span class={styles.inviteListItemName}>
                          {$email}{" "}
                          <div class={styles.roleBadge}>
                            {$($role, (role) => {
                              switch (role) {
                                case "owner":
                                  return (
                                    <Badge
                                      icon={<ProjectOwner />}
                                      label={translate("workspace.project.roles.owner.title")}
                                    />
                                  );
                                case "admin":
                                  return (
                                    <Badge
                                      icon={<ProjectAdmin />}
                                      label={translate("workspace.project.roles.admin.title")}
                                    />
                                  );
                                case "collaborator":
                                  return (
                                    <Badge
                                      icon={<ProjectCollaborator />}
                                      label={translate("workspace.project.roles.collaborator.title")}
                                    />
                                  );
                                case "viewer":
                                  return (
                                    <Badge
                                      icon={<ProjectViewer />}
                                      label={translate("workspace.project.roles.viewer.title")}
                                    />
                                  );
                              }
                            })}
                          </div>
                        </span>

                        <ul class={styles.userMeta}>
                          <li>
                            <Time />{" "}
                            {$($timestamp, $currentLanguage, (ts, lang) => formatInviteDate(ts, lang!))}
                          </li>
                        </ul>
                      </li>
                    );
                  },
                )}
              </ul>
            </CollapsibleListSection>,
          )}
        </div>
      </CardContent>
    </>
  );
}

interface UsersListProps {
  $users: Readable<ProjectUser[]>;
}

function UsersList(props: UsersListProps, ctx: ViewContext) {
  const { $currentLanguage } = ctx.getStore(LanguageStore);

  return cond(
    $(props.$users, (x) => x.length === 0),
    <p class={styles.emptyUserListMessage}>No users.</p>,
    <ul class={styles.userList}>
      {repeat(
        props.$users,
        (u) => u.id,
        ($user, $index, ctx) => {
          const theme = ctx.getStore(ThemeStore);

          const $name = $($user, (x) => x.name);
          const $color = $($user, (x) => x.color);
          const $themeVars = theme.getThemeVariables$($color);

          return (
            <li class={styles.userListItem}>
              <div class={styles.userLayout}>
                <div class={styles.userInfo}>
                  <div class={styles.userIcon}>
                    <UserAvatar src={$($user, (u) => u.avatar ?? "")} />
                  </div>

                  <div class={styles.userText}>
                    <span class={styles.userName}>
                      <span class={styles.userNameLabel} style={$themeVars}>
                        {$name}
                      </span>
                    </span>

                    <ul class={styles.userMeta}>
                      <li>
                        <Mail /> {$($user, (u) => u.email)}
                      </li>
                      <li>
                        <Time /> {$($user, $currentLanguage, (u, lang) => formatJoinDate(u.joinedAt, lang!))}
                      </li>
                    </ul>
                  </div>
                </div>

                {/* {cond(
            $($userRole, (role) => role === "admin"), // Includes owner
            <div class={styles.userActions}>
              <MoreMenu
                $$open={$$menuIsOpen}
                size="small"
                options={[
                  // {
                  //   label: translate("common.edit"),
                  //   icon: <EditIcon />,
                  //   callback: () => {
                  //     $$editIsOpen.set(true);
                  //   },
                  // },
                  {
                    label: translate("common.delete"),
                    icon: <Trash />,
                    variant: "destructive",
                    callback: async () => {
                      // await projects.deleteProject($project.get().id);
                    },
                  },
                ]}
              />
            </div>,
          )} */}
              </div>

              <div class={styles.projectDivider} />
            </li>
          );
        },
      )}
    </ul>,
  );
}

function formatJoinDate(date: string | Date, lang: string) {
  return new Intl.DateTimeFormat([lang], {
    year: "numeric",
    month: "long",
  }).format(new Date(date));
}

function formatInviteDate(date: string | Date, lang: string) {
  return new Intl.DateTimeFormat([lang], {
    dateStyle: "long",
  }).format(new Date(date));
}
