import { ViewContext, Readable } from "@manyducks.co/dolla";
import styles from "./NavHeader.module.css";

interface NavHeaderProps {
  title: string | Readable<string>;
}

export function NavHeader(props: NavHeaderProps, ctx: ViewContext) {
  return (
    <header class={styles.header}>
      <span class={styles.title}>{props.title}</span>
    </header>
  );
}
