export default {
  "layout": "PlaceLayout_layout_7e147",
  "content": "PlaceLayout_content_7e147",
  "header": "PlaceLayout_header_7e147",
  "tabless": "PlaceLayout_tabless_7e147",
  "titleArea": "PlaceLayout_titleArea_7e147",
  "title": "PlaceLayout_title_7e147",
  "backButton": "PlaceLayout_backButton_7e147",
  "withBackButton": "PlaceLayout_withBackButton_7e147",
  "titleContent": "PlaceLayout_titleContent_7e147",
  "titleText": "PlaceLayout_titleText_7e147",
  "mainText": "PlaceLayout_mainText_7e147",
  "subText": "PlaceLayout_subText_7e147",
  "deemphasized": "PlaceLayout_deemphasized_7e147",
  "nav": "PlaceLayout_nav_7e147",
  "active": "PlaceLayout_active_7e147",
  "tabs": "PlaceLayout_tabs_7e147",
  "titleControls": "PlaceLayout_titleControls_7e147",
  "hidden": "PlaceLayout_hidden_7e147",
  "iconButton": "PlaceLayout_iconButton_7e147",
  "vibrant": "PlaceLayout_vibrant_7e147",
  "badge": "PlaceLayout_badge_7e147",
  "titleIcon": "PlaceLayout_titleIcon_7e147",
  "titleButton": "PlaceLayout_titleButton_7e147",
  "chatButton": "PlaceLayout_chatButton_7e147"
};import "ni:sha-256;yM8gHI9sOZi3o6RcXIHtyCT3NU5FCcyvwFpjrBxrB2Y";