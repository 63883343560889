export default {
  "content": "Details_content_fa5e0",
  "projectEdit": "Details_projectEdit_fa5e0",
  "projectEditButton": "Details_projectEditButton_fa5e0",
  "projectHeader": "Details_projectHeader_fa5e0",
  "projectHeaderIcon": "Details_projectHeaderIcon_fa5e0",
  "projectHeaderText": "Details_projectHeaderText_fa5e0",
  "charmsBar": "Details_charmsBar_fa5e0",
  "projectSections": "Details_projectSections_fa5e0",
  "projectSectionLink": "Details_projectSectionLink_fa5e0",
  "projectSectionLabel": "Details_projectSectionLabel_fa5e0",
  "projectSectionDescription": "Details_projectSectionDescription_fa5e0",
  "projectSectionControls": "Details_projectSectionControls_fa5e0",
  "projectSectionToggleLabel": "Details_projectSectionToggleLabel_fa5e0",
  "cardSwitchList": "Details_cardSwitchList_fa5e0",
  "cardSwitchListItem": "Details_cardSwitchListItem_fa5e0",
  "cardSwitchIcon": "Details_cardSwitchIcon_fa5e0",
  "cardSwitchLabel": "Details_cardSwitchLabel_fa5e0",
  "cardLaunchButton": "Details_cardLaunchButton_fa5e0",
  "userList": "Details_userList_fa5e0",
  "emptyUserListMessage": "Details_emptyUserListMessage_fa5e0",
  "userListItem": "Details_userListItem_fa5e0",
  "userLayout": "Details_userLayout_fa5e0",
  "userInfo": "Details_userInfo_fa5e0",
  "userIcon": "Details_userIcon_fa5e0",
  "roleBadge": "Details_roleBadge_fa5e0",
  "userText": "Details_userText_fa5e0",
  "userName": "Details_userName_fa5e0",
  "userNameLabel": "Details_userNameLabel_fa5e0",
  "userStatus": "Details_userStatus_fa5e0",
  "userActions": "Details_userActions_fa5e0",
  "userMeta": "Details_userMeta_fa5e0",
  "cardLauncherIcon": "Details_cardLauncherIcon_fa5e0",
  "menuPanelIcon": "Details_menuPanelIcon_fa5e0",
  "addUserSection": "Details_addUserSection_fa5e0",
  "justifyEnd": "Details_justifyEnd_fa5e0",
  "toolButtonIcon": "Details_toolButtonIcon_fa5e0",
  "editForm": "Details_editForm_fa5e0",
  "editFormGroup": "Details_editFormGroup_fa5e0",
  "inviteListItem": "Details_inviteListItem_fa5e0",
  "inviteListItemName": "Details_inviteListItemName_fa5e0"
};import "ni:sha-256;YJtZ52Iu8N_ri-5Tay1qduusm20WpxHuSt8VYIe_eqA";