import { adjustAccentColor } from "@helpers/makeTheme";
import { relativePastTime } from "@helpers/relativePastTime";
import {
  $,
  $$,
  cond,
  DialogStore,
  LanguageStore,
  repeat,
  RouterStore,
  type Readable,
  type ViewContext,
} from "@manyducks.co/dolla";
import { type Renderable } from "@manyducks.co/dolla/lib/types";
import { AuthStore } from "@stores/AuthStore";
import { ClockStore } from "@stores/ClockStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { TasksStore } from "@stores/TasksStore";
import { ThemeStore } from "@stores/ThemeStore";
import { ConfirmDelete } from "@views/@dialogs";
import ArrowUpDoubleLine from "@views/@icons/ArrowUpDoubleLine";
import ArrowUpSingleLine from "@views/@icons/ArrowUpSingleLine";
import Calendar from "@views/@icons/Calendar";
import ErrorCircle from "@views/@icons/ErrorCircle";
import TimeIcon from "@views/@icons/Time";
import TrashIcon from "@views/@icons/Trash";
import UserIcon from "@views/@icons/User";
import { Checkbox } from "@views/Checkbox";
import { HoverMenu } from "@views/HoverMenu";
import { MoreMenu } from "@views/MoreMenu";
import { addDays, isBefore, isSameDay } from "date-fns";
import { type Task } from "schemas";
import { AssignMenu } from "./AssignMenu";
import { TaskEditDialog } from "./TaskEditDialog/TaskEditDialog";
import styles from "./TaskListItem.module.css";
import Paperclip from "@views/@icons/Paperclip";
import prettyBytes from "pretty-bytes";

interface TaskListItemProps {
  $task: Readable<Task>;

  /**
   * Items to display along the top row of the task to provide more context about its state.
   */
  $charms?: Readable<Renderable>;

  $timestamp?: Readable<string>;

  $theme?: Readable<any>;

  interactive?: boolean | Readable<boolean>;

  onClick?: (e: MouseEvent) => void;

  scrollToTask?: (taskId: number) => void;
}

export function TaskListItem(props: TaskListItemProps, ctx: ViewContext) {
  const projects = ctx.getStore(ProjectsStore);
  const tasks = ctx.getStore(TasksStore);
  const auth = ctx.getStore(AuthStore);
  const theme = ctx.getStore(ThemeStore);
  const clock = ctx.getStore(ClockStore);
  const dialog = ctx.getStore(DialogStore);
  const router = ctx.getStore(RouterStore);
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);

  const { $task, $charms, scrollToTask } = props;

  const $isSelected = $(router.$params, $task, (params, task) => params.taskId == task.id);

  const $$listItemElement = $$<HTMLLIElement>();

  const $interactive = $(props.interactive ?? true);
  const $noninteractive = $($interactive, (x) => !x);

  const $timestamp = $(props.$timestamp);
  const $defaultTitle = translate("views.taskListItem.unnamedFallbackTitle");
  const $title = $($task, $defaultTitle, (task, defaultTitle) => task.title || defaultTitle);
  const $status = $($task, (t) => t.status);
  const $priority = $($task, (t) => t.priority);
  const $isTitled = $($task, (task) => !!task.title);
  const $isUntitled = $($title, $defaultTitle, (title, defaultTitle) => title === defaultTitle);

  const $assignedUser = $($task, auth.$users, (task, users) =>
    users.find((u) => u.id === task.assignedUserId),
  );

  const $$menuOpen = $$(false);

  const $$stageMenuOpen = $$(false);

  const $highlighted = $($task, tasks.$$highlightTaskId, (t, id) => t.id === id);
  ctx.observe($highlighted, (highlighted) => {
    if (highlighted) {
      const button = $$listItemElement.get()!.querySelector("button");

      if (button) {
        button.addEventListener(
          "animationend",
          () => {
            tasks.$$highlightTaskId.set(null);
          },
          { once: true },
        );
      }
    }
  });

  const $$assignMenuIsOpen = $$(false);
  const $$assignMenuAnchor = $$<HTMLElement>();

  function openAssignMenu(e: Event) {
    $$assignMenuAnchor.set(e.currentTarget as HTMLElement);
    $$assignMenuIsOpen.set(true);
  }

  const $projectColor = $($task, projects.$cache, theme.$isDark, (task, projects, dark) => {
    const project = projects.find((p) => p.id === task.projectId)!;
    return adjustAccentColor(project.color, dark);
  });

  const $assignedUserColor = $($task, auth.$users, theme.$isDark, (task, users, dark) => {
    if (task.assignedUserId != null) {
      const user = users.find((u) => u.id === task.assignedUserId);
      if (user) {
        return adjustAccentColor(user.color, dark);
      }
    }
    return undefined;
  });

  return (
    <li class={styles.taskListItem} ref={$$listItemElement} data-task-id={$($task, (t) => t.id)}>
      <button
        class={[
          styles.task,
          {
            [styles.completed]: $($task, (t) => t.completedAt != null),
            [styles.focused]: $($$menuOpen, $$stageMenuOpen, (a, b) => a || b),
            [styles.highlighted]: $highlighted,
            [styles.selected]: $isSelected,
          },
        ]}
        onClick={(e) => {
          props.onClick?.(e);

          if (!e.defaultPrevented) {
            e.preventDefault();

            const interactive = $interactive.get();
            if (!interactive) {
              return;
            }

            const task = $task.get();
            dialog.open(TaskEditDialog, {
              projectId: task.projectId,
              task,
              color: $projectColor.get(),
            });
          }
        }}
        onDragStart={(e) => {
          ctx.log("dragstart", e);

          e.dataTransfer!.setData("data/task-id", String($task.get().id));
          e.dataTransfer!.effectAllowed = "move";
        }}
        draggable={true}
      >
        {cond(
          $charms,
          <div class={styles.charmsBar} style={props.$theme}>
            {$charms}
          </div>,
        )}

        <div class={styles.taskLayout}>
          <div class={styles.taskCheckbox} onClick={(e) => e.stopPropagation()}>
            <Checkbox
              disabled={$noninteractive}
              $value={$($task, (t) => t.completedAt != null)}
              onChange={(value) => {
                const task = $task.get();
                tasks.setCompleted(task.id, value);
              }}
            />
          </div>

          <div class={styles.taskContent} draggable={false}>
            <span class={styles.taskTitle}>
              {$($priority, (p) => {
                switch (p) {
                  case null:
                    return null;
                  case 1:
                    return (
                      <span class={[styles.priorityBadge, styles.prioritySoon]}>
                        <ArrowUpSingleLine />
                      </span>
                    );
                  case 2:
                    return (
                      <span class={[styles.priorityBadge, styles.prioritySoon]}>
                        <ArrowUpDoubleLine />
                      </span>
                    );
                }
              })}
              <span class={{ [styles.untitled]: $isUntitled, [styles.taskTitle]: $isTitled }}>{$title}</span>
            </span>

            {cond($status, <p class={styles.taskDescription}>{$status}</p>)}

            {cond(
              $($task, (t) => t.tags.length > 0),
              <ul class={styles.taskTagList}>
                {repeat(
                  $($task, (t) => [...t.tags].sort()),
                  (tag) => tag,
                  ($tag) => {
                    return <li class={styles.taskTag}>{$tag}</li>;
                  },
                )}
              </ul>,
            )}

            <section class={styles.taskQuickOptions}>
              {cond(
                $assignedUser,
                <button
                  class={[
                    styles.taskAssignedUser,
                    { [styles.menuOpen]: $$assignMenuIsOpen, [styles.interactive]: $interactive },
                  ]}
                  disabled={$noninteractive}
                  style={{ "--assigned-user-color": $assignedUserColor }}
                  onClick={(e) => {
                    e.stopPropagation();
                    openAssignMenu(e);
                  }}
                >
                  <UserIcon />
                  {$($assignedUser, (u) => u?.name)}
                </button>,
                <button
                  class={[
                    styles.taskAssignedUser,
                    { [styles.menuOpen]: $$assignMenuIsOpen, [styles.interactive]: $interactive },
                  ]}
                  disabled={$noninteractive}
                  style={{ color: $assignedUserColor }}
                  onClick={(e) => {
                    e.stopPropagation();
                    openAssignMenu(e);
                  }}
                >
                  <UserIcon />
                  {translate("views.taskListItem.meta.notAssignedLabel")}
                </button>,
              )}

              {cond(
                $($task, clock.$hour, (t, hour) => t.dueDate),
                <div
                  class={[styles.taskDueDate, { [styles.inPast]: $($task, (t) => isDateInPast(t.dueDate!)) }]}
                >
                  {cond(
                    $($task, clock.$hour, (t, hour) => isDateInPast(t.dueDate!)),
                    <ErrorCircle />,
                    <Calendar />,
                  )}
                  {$($task, $currentLanguage, clock.$hour, (task, lang, hour) => {
                    const [y, m, d] = task.dueDate!.split("-").map((n) => Number(n));
                    const date = new Date(y, m - 1, d);
                    const now = new Date();

                    if (isSameDay(date, now)) {
                      return translate("views.taskListItem.meta.dueDateLabel_today").get();
                    } else if (isSameDay(date, addDays(now, 1))) {
                      return translate("views.taskListItem.meta.dueDateLabel_tomorrow").get();
                    } else {
                      const value = new Intl.DateTimeFormat([lang!, "en"], {
                        month: "short",
                        day: "numeric",
                      }).format(date);
                      return translate("views.taskListItem.meta.dueDateLabel_value", { value }).get();
                    }
                  })}
                </div>,
              )}

              {cond(
                $($task, (t) => t.attachments.length > 0),
                <div class={styles.taskAttachments}>
                  <Paperclip /> {$($task, (t) => t.attachments.length)} (
                  {$($task, (t) => {
                    const totalBytes = t.attachments.reduce((sum, f) => sum + f.sizeInBytes, 0);
                    console.log({ t, totalBytes });
                    return prettyBytes(totalBytes);
                  })}
                  )
                </div>,
              )}
            </section>

            {/* {cond(
              $status,
              <section class={[styles.metaSection]}>
                <span class={styles.taskStatus} style={{ "--color-user-accent": $assignedUserColor }}>
                  {$status}
                </span>
              </section>,
            )} */}

            {cond(
              $($task, $timestamp, (task, timestamp) => task.completedAt != null || timestamp),
              <section class={[styles.metaSection, styles.timestampSection]}>
                <div class={styles.metaIcon}>
                  <TimeIcon />
                </div>
                <span class={styles.taskTimestamp}>
                  {$($timestamp, $task, $currentLanguage, (timestamp, task, lang) => {
                    if (timestamp) {
                      return timestamp;
                    } else {
                      const timestamp = relativePastTime(lang!, task.completedAt!);
                      if (timestamp) {
                        return translate("relativeTime.completedAtTimestamp", { timestamp }).get();
                      } else {
                        return translate("relativeTime.completedNow").get();
                      }
                    }
                  })}
                </span>
              </section>,
            )}
          </div>

          {cond(
            $interactive,
            <div class={styles.taskMenu}>
              <MoreMenu
                $$open={$$menuOpen}
                size="small"
                color={$projectColor}
                options={[
                  {
                    label: translate("common.delete"),
                    icon: <TrashIcon />,
                    variant: "destructive",
                    callback: () => {
                      dialog.open(ConfirmDelete, {
                        message: translate("views.taskListItem.deleteDialog.message"),
                        itemName: $task.get().title,
                        onConfirm: () => {
                          tasks.deleteTask($task.get().id);
                        },
                      });
                    },
                  },
                ]}
              />
            </div>,
          )}
        </div>
      </button>

      <HoverMenu
        $$open={$$assignMenuIsOpen}
        $anchorRef={$$assignMenuAnchor}
        color={$projectColor}
        distanceFromAnchor={14}
        preferHorizontalAlignment="center"
        preferVerticalAlignment="below"
      >
        <div class={styles.assignMenu}>
          <AssignMenu
            $projectId={$($task, (t) => t.projectId)}
            $value={$($task, (t) => t.assignedUserId)}
            onChange={(user) => {
              const task = $task.get();
              tasks.assignTo(user?.id ?? null, task.id);
              $$assignMenuIsOpen.set(false);
            }}
          />
        </div>
      </HoverMenu>

      <div class={styles.itemDivider} />
    </li>
  );
}

function isDateInPast(dateString: string) {
  const [y, m, d] = dateString.split("-").map((n) => Number(n));
  const date = new Date(y, m - 1, d);
  const now = new Date();

  return !isSameDay(now, date) && isBefore(date, now);
}
