import { makeDebouncer } from "@helpers/makeDebouncer";
import { $$, type ViewContext } from "@manyducks.co/dolla";
import styles from "./DuckLoader.module.css";
import { DuckLoaderState } from "./makeLoaderState";

interface DuckLoaderProps {
  state: DuckLoaderState;
}

export function DuckLoader({ state }: DuckLoaderProps, ctx: ViewContext) {
  const debouncer = makeDebouncer(300);
  const $$animating = $$(state.$visible.get());

  ctx.observe(state.$visible, (visible) => {
    if (visible) {
      $$animating.set(true);
      debouncer.cancel();
    } else {
      debouncer.queue(() => {
        $$animating.set(false);
      });
    }
  });

  return (
    <div className={[styles.loader, { [styles.visible]: state.$visible, [styles.animating]: $$animating }]}>
      <div class={styles.loaderPane}>
        <div className={{ [styles.container]: true, [styles.animating]: $$animating }}>
          <div className={[styles.background, styles.outerCircle]}>
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 200 200"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g transform="matrix(0.530261,0,0,0.530261,-118.326,-95.933)">
                <path d="M372.281,222.265C372.281,200.49 389.959,182.813 411.733,182.813C433.508,182.813 451.185,200.49 451.185,222.265C462.073,203.408 486.221,196.937 505.078,207.824C523.935,218.711 530.406,242.86 519.519,261.717C538.376,250.83 562.524,257.3 573.412,276.158C584.299,295.015 577.828,319.163 558.971,330.05C580.745,330.05 598.423,347.728 598.423,369.503C598.423,391.277 580.745,408.955 558.971,408.955C577.828,419.842 584.299,443.991 573.412,462.848C562.524,481.705 538.376,488.175 519.519,477.288C530.406,496.145 523.935,520.294 505.078,531.181C486.221,542.068 462.073,535.597 451.185,516.74C451.185,538.515 433.508,556.193 411.733,556.193C389.959,556.193 372.281,538.515 372.281,516.74C361.394,535.597 337.245,542.068 318.388,531.181C299.531,520.294 293.061,496.145 303.948,477.288C285.091,488.175 260.942,481.705 250.055,462.848C239.168,443.991 245.638,419.842 264.495,408.955C242.721,408.955 225.043,391.277 225.043,369.503C225.043,347.728 242.721,330.05 264.495,330.05C245.638,319.163 239.168,295.015 250.055,276.158C260.942,257.3 285.091,250.83 303.948,261.717C293.061,242.86 299.531,218.711 318.388,207.824C337.245,196.937 361.394,203.408 372.281,222.265Z" />
              </g>
            </svg>
          </div>
          <div className={[styles.background, styles.innerCircle]}>
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 200 200"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g transform="matrix(0.530261,0,0,0.530261,-118.326,-95.933)">
                <path d="M372.281,222.265C372.281,200.49 389.959,182.813 411.733,182.813C433.508,182.813 451.185,200.49 451.185,222.265C462.073,203.408 486.221,196.937 505.078,207.824C523.935,218.711 530.406,242.86 519.519,261.717C538.376,250.83 562.524,257.3 573.412,276.158C584.299,295.015 577.828,319.163 558.971,330.05C580.745,330.05 598.423,347.728 598.423,369.503C598.423,391.277 580.745,408.955 558.971,408.955C577.828,419.842 584.299,443.991 573.412,462.848C562.524,481.705 538.376,488.175 519.519,477.288C530.406,496.145 523.935,520.294 505.078,531.181C486.221,542.068 462.073,535.597 451.185,516.74C451.185,538.515 433.508,556.193 411.733,556.193C389.959,556.193 372.281,538.515 372.281,516.74C361.394,535.597 337.245,542.068 318.388,531.181C299.531,520.294 293.061,496.145 303.948,477.288C285.091,488.175 260.942,481.705 250.055,462.848C239.168,443.991 245.638,419.842 264.495,408.955C242.721,408.955 225.043,391.277 225.043,369.503C225.043,347.728 242.721,330.05 264.495,330.05C245.638,319.163 239.168,295.015 250.055,276.158C260.942,257.3 285.091,250.83 303.948,261.717C293.061,242.86 299.531,218.711 318.388,207.824C337.245,196.937 361.394,203.408 372.281,222.265Z" />
              </g>
            </svg>
          </div>
          <span className={styles.emoji}>🦆</span>
        </div>
      </div>
    </div>
  );
}
