import { $, cond, type Readable, type ViewContext } from "@manyducks.co/dolla";
import styles from "./IconButton.module.css";

interface IconButtonProps {
  type?: "button" | "submit" | "reset";
  onClick?: (e: MouseEvent) => void;
  disabled?: boolean | Readable<boolean>;
  size?: "normal" | "small";
  variant?: "transparent" | "filled" | "destructive";
  active?: boolean | Readable<boolean>;
  tooltip?: string | Readable<string>;
  label?: string | Readable<string>;
  showLabel?: boolean | Readable<boolean>;
  numberBadge?: number | Readable<number> | Readable<number | undefined>;
}

/**
 * Square button with an icon in the middle. Used for toolbars and UI controls.
 */
export function IconButton(props: IconButtonProps, ctx: ViewContext) {
  const $disabled = $(props.disabled);
  const $tooltip = $(props.tooltip);
  const $label = $(props.label);
  const $showLabel = $(props.showLabel);
  const $numberBadge = $(props.numberBadge);
  const { type, size, variant, active, onClick } = props;

  return (
    <button
      onClick={(e) => {
        if ($disabled.get() !== true && onClick) {
          onClick(e);
        }
      }}
      class={[
        styles.button,
        styles[variant ?? "transparent"],
        size && styles[size],
        { [styles.withLabel]: $showLabel, [styles.active]: active },
      ]}
      type={type ?? "button"}
      disabled={$disabled}
      title={$tooltip}
    >
      <div class={styles.icon}>{ctx.outlet()}</div>
      {cond($showLabel, <span class={styles.label}>{$label}</span>)}
      {cond($numberBadge, <div class={styles.numberBadge}>{$numberBadge}</div>)}
    </button>
  );
}
