import { $, RouterStore, type ViewContext } from "@manyducks.co/dolla";
import { List } from "./List/List";
import styles from "./ProjectTasks.module.css";
import { CardDetailStack } from "@views/CardDetailStack";
import { Details } from "./Details/Details";
import { ProjectsStore } from "@stores/ProjectsStore";

export interface ProjectTasksProps {}

export function ProjectTasks(props: ProjectTasksProps, ctx: ViewContext) {
  ctx.name = "ProjectTasks";
  const router = ctx.getStore(RouterStore);
  const projects = ctx.getStore(ProjectsStore);

  const $projectId = $(router.$params, (p) => (p.projectId != null ? Number(p.projectId) : undefined));
  const $taskId = $(router.$params, (p) => (p.taskId != null ? Number(p.taskId) : undefined));

  const $project = $(projects.$cache, $projectId, (projects, id) => projects.find((p) => p.id === id));

  return (
    <div class={styles.layout}>
      <CardDetailStack
        showDetail={$($taskId, (id) => id != null)}
        listContent={<List />}
        detailContent={<Details $project={$project} $taskId={$taskId} />}
      />
    </div>
  );
}
