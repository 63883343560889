import { $, $$, HTTPStore, LanguageStore, RouterStore, type StoreContext } from "@manyducks.co/dolla";
import { produce } from "immer";
import { type User } from "../schemas";
import { PushStore } from "./PushStore.js";

const AUTH_LOCAL_STORAGE_KEY = "quack-auth";

type AuthToken = {
  token: string;
  expiresAt: Date;
};

type MeUpdateOptions = {
  name?: string;
  avatar?: string;
  status?: string;
  color?: string;
  language?: string;
};

/**
 * Manages authentication and user info.
 */
export function AuthStore(ctx: StoreContext) {
  const http = ctx.getStore(HTTPStore);
  const router = ctx.getStore(RouterStore);
  const push = ctx.getStore(PushStore);
  const lang = ctx.getStore(LanguageStore);

  const $$auth = $$<AuthToken>();
  const $$userId = $$<number>();
  const $$users = $$<User[]>([]);
  const $$updateAvailable = $$<boolean>(false);

  const $me = $($$userId, $$users, (id, users) => {
    return users?.find((u) => u.id === id);
  });

  let version: string | null = null;

  const $token = $($$auth, (a) => a?.token);
  const $isLoggedIn = $($$auth, (a) => (a && a.token != null && a.expiresAt > new Date()) || false);

  const stored = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);

  if (stored) {
    const parsed = JSON.parse(stored);
    parsed.expiresAt = new Date(parsed.expiresAt);

    $$auth.set(parsed);
  }

  // Update UI to match user's preferred language.
  ctx.observe($me, (user) => {
    if (user && user.language && user.language !== lang.$currentLanguage.get()) {
      lang.setLanguage(user.language);
    }
  });

  // Add auth to API calls made through the @http service
  // and redirect to login page on 401 or 403 responses.
  ctx.onConnected(() => {
    const removeMiddleware = http.middleware(async (req, next) => {
      const loggedIn = $isLoggedIn.get();

      if (loggedIn && req.uri.startsWith("/api/")) {
        const token = $token.get();
        if (token) {
          req.headers.set("authorization", "Bearer " + token);
        }
      }

      const response = await next();

      if (response.status === 401 || response.status === 403) {
        clearLocalStorage();
        $$auth.set(undefined);
        router.navigate("/login");
      }
    });

    ctx.onDisconnected(removeMiddleware);

    if ($isLoggedIn.get()) {
      getUsers();
    }
  });

  // Set user accent color on body.

  async function getUsers() {
    const res = await http.get<{ userId: number; users: User[] }>("/api/users");

    $$userId.set(res.body.userId);
    $$users.set(res.body.users);
  }

  async function getUpdateStatus() {
    return http.get<string>("/api/version").then((res) => {
      if (!version) {
        version = res.body;
      }

      $$updateAvailable.set(res.body !== version);
      return res.body !== version;
    });
  }

  function setAuth(token: string, expiresAt: Date) {
    $$auth.set({
      token,
      expiresAt,
    });

    localStorage.setItem(
      AUTH_LOCAL_STORAGE_KEY,
      JSON.stringify({
        token,
        expiresAt,
      }),
    );
  }

  async function logIn(email: string, password: string) {
    return http
      .post<{ token: string; expiresAt: string }>("/api/auth/login", {
        body: { email: email.trim().toLowerCase(), password },
      })
      .then(async (res) => {
        const token = res.body.token;
        const expiresAt = new Date(res.body.expiresAt);

        setAuth(token, expiresAt);

        await getUsers();

        return res;
      });
  }

  async function signUp(email: string, promoCode?: string) {
    return http.post("/api/auth/signup", {
      body: { email: email.trim().toLowerCase(), promoCode },
    });
  }

  /**
   * Removes user-specific local storage options to avoid leaking info between logins.
   */
  function clearLocalStorage() {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
    localStorage.removeItem("quack-current-cards");
    localStorage.removeItem("quack-focused-card");
    localStorage.removeItem("quack-quick-add-content");
    localStorage.removeItem("quack-current-language");
    localStorage.removeItem("quack-color-scheme");
  }

  async function logOut() {
    if ($isLoggedIn.get()) {
      await push.disablePushNotifications();
      await http.delete("/api/auth/login");
    }

    clearLocalStorage();

    $$auth.set(undefined);
    $$userId.set(undefined);
  }

  async function changePassword(newPassword: string) {
    return http
      .post("/api/auth/password-reset", {
        body: { password: newPassword },
      })
      .then(() => {
        logOut(); // Log user out so they can log in with new credentials
      });
  }

  async function updateMe(options: MeUpdateOptions) {
    return http.put<User, MeUpdateOptions>("/api/users/me", { body: options }).then((res) => {
      $$users.update(
        produce((users) => {
          const me = users.find((x) => x.id === res.body.id);
          if (me) {
            Object.assign(me, res.body);
          }
        }),
      );
    });
  }

  interface caches {
    users: Array<number>;
    projects: Array<number>;
    notes: Array<number>;
    tasks: Array<number>;
  }

  async function validateCache(caches: any) {
    // return http
    //   .post<Array<number>>("/api/users/validate-cache", { body: { cache: $$users.get().map((x) => x.id) } })
    //   .then((res) => {
    //     $$users.update((users) => users.filter((user) => !res.body.includes(user.id)));
    //   });
    return http.post<caches>("/api/users/validate-cache", { body: caches }).then((res) => {
      return res.body;
    });
  }

  function updateCache(cache: Array<number>) {
    if (cache.length > 0) {
      $$users.update((users) => users.filter((user) => !cache.includes(user.id)));
    }
  }

  return {
    $me,
    $users: $($$users),
    $updateAvailable: $($$updateAvailable),
    $token,
    $isLoggedIn,

    setAuth,

    getUsers,
    logIn,
    logOut,
    signUp,
    changePassword,
    updateMe,
    getUpdateStatus,
    validateCache,
    updateCache,

    userUpdateReceived: (user: User) => {
      ctx.log({ user });
      $$users.update(
        produce((users) => {
          const found = users.find((x) => x.id === user.id);

          if (found) {
            Object.assign(found, user);
          } else {
            users.push(user);
          }
        }),
      );
    },
  };
}
