export default {
  "noteListItem": "NoteListItem_noteListItem_61046",
  "itemDivider": "NoteListItem_itemDivider_61046",
  "note": "NoteListItem_note_61046",
  "highlight": "NoteListItem_highlight_61046",
  "focused": "NoteListItem_focused_61046",
  "selected": "NoteListItem_selected_61046",
  "noteTitle": "NoteListItem_noteTitle_61046",
  "noteLayout": "NoteListItem_noteLayout_61046",
  "noteText": "NoteListItem_noteText_61046",
  "noteControls": "NoteListItem_noteControls_61046",
  "untitled": "NoteListItem_untitled_61046",
  "noteTagList": "NoteListItem_noteTagList_61046",
  "noteTag": "NoteListItem_noteTag_61046",
  "metaList": "NoteListItem_metaList_61046",
  "metaIcon": "NoteListItem_metaIcon_61046",
  "charmsBar": "NoteListItem_charmsBar_61046"
};import "ni:sha-256;Xbmg3pJuqV8RlpbCoeYf6zN8DCfcT1bJHK9s_nY_1V4";