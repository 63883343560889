export default {
  "container": "MoreMenu_container_8a154",
  "small": "MoreMenu_small_8a154",
  "button": "MoreMenu_button_8a154",
  "card": "MoreMenu_card_8a154",
  "open": "MoreMenu_open_8a154",
  "icon": "MoreMenu_icon_8a154",
  "menu": "MoreMenu_menu_8a154",
  "listButton": "MoreMenu_listButton_8a154",
  "destructive": "MoreMenu_destructive_8a154"
};import "ni:sha-256;UDelc2-nbnXr4LIPjbLiRclW12-MwaE3ly3wqcMN_q8";