import { type Readable, type ViewContext } from "@manyducks.co/dolla";
import { type Renderable } from "@manyducks.co/dolla/lib/types";
import styles from "./ChatDetailStack.module.css";

interface ChatDetailStackProps {
  showDetail: boolean | Readable<boolean>;
  listContent: Renderable;
  detailContent: Renderable;
}

export function ChatDetailStack(props: ChatDetailStackProps, ctx: ViewContext) {
  return (
    <div class={[styles.layout]}>
      <div class={[styles.frame, styles.listFrame, { [styles.hidden]: props.showDetail }]}>
        {props.listContent}
      </div>
      <div class={[styles.shade, { [styles.show]: props.showDetail }]} />
      <div class={[styles.frame, styles.detailFrame, { [styles.show]: props.showDetail }]}>
        {props.detailContent}
      </div>
    </div>
  );
}
