export default {
  "workspace": "Workspace_workspace_12469",
  "geometryLayer": "Workspace_geometryLayer_12469",
  "gradientLayer": "Workspace_gradientLayer_12469",
  "navLayer": "Workspace_navLayer_12469",
  "navCards": "Workspace_navCards_12469",
  "navCardShade": "Workspace_navCardShade_12469",
  "visible": "Workspace_visible_12469",
  "navCard": "Workspace_navCard_12469",
  "navCardContent": "Workspace_navCardContent_12469",
  "navCardScrollContainer": "Workspace_navCardScrollContainer_12469",
  "left": "Workspace_left_12469",
  "open": "Workspace_open_12469",
  "right": "Workspace_right_12469",
  "navRails": "Workspace_navRails_12469",
  "navRail": "Workspace_navRail_12469",
  "navList": "Workspace_navList_12469",
  "navButton": "Workspace_navButton_12469",
  "navButtonCircle": "Workspace_navButtonCircle_12469",
  "active": "Workspace_active_12469",
  "grabber": "Workspace_grabber_12469",
  "layout": "Workspace_layout_12469",
  "overlaid": "Workspace_overlaid_12469",
  "main": "Workspace_main_12469",
  "leftNavPersistent": "Workspace_leftNavPersistent_12469",
  "rightNavPersistent": "Workspace_rightNavPersistent_12469"
};import "ni:sha-256;tcg_lvbLo4ZF2SvEt6rKbLS0OhIG7ZZeOjsEDOwo2wY";