import { cond, $$, $, type Readable, type ViewContext, Renderable } from "@manyducks.co/dolla";
import ArrowRightIcon from "@views/@icons/ArrowRight";
import CheckIcon from "@views/@icons/Check";
import CloseIcon from "@views/@icons/Close";
import styles from "./Switch.module.css";

interface SwitchProps {
  id?: string;
  $value: Readable<boolean>;
  disabled?: boolean | Readable<boolean>;
  offIcon?: Renderable | Readable<Renderable>;
  onIcon?: Renderable | Readable<Renderable>;
  onChange?: (value: boolean) => void;
}

export function Switch(props: SwitchProps, ctx: ViewContext) {
  const { $value } = props;

  const $disabled = $(props.disabled ?? false);

  const $$pointerStart = $$(0);
  const $$isPressed = $$(false);
  const $$isDragging = $$(false);
  const $$dragStart = $$(0);
  const $$dragOffset = $$(0);

  let justChangedByDrag = false; // Prevent input onchange from flipping value immediately if value was changed by dragging.

  const $id = $(props.id ?? ctx.uniqueId);

  function onPointerDown(e: PointerEvent) {
    $$pointerStart.set(e.pageX);
    $$isPressed.set(true);

    window.addEventListener("pointermove", onPointerMove);
    window.addEventListener("pointerup", onPointerUp);
  }

  function onPointerMove(e: PointerEvent) {
    // Start handling as a drag if position has moved at least 5 pixels from start.
    if (!$$isDragging.get() && Math.abs(e.pageX - $$pointerStart.get()) > 5) {
      window.removeEventListener("pointermove", onPointerMove);
      window.removeEventListener("pointerup", onPointerUp);
      onDragStart(e);
    }
  }

  function onPointerUp(e: PointerEvent) {
    window.removeEventListener("pointermove", onPointerMove);
    window.removeEventListener("pointerup", onPointerUp);
    $$isPressed.set(false);
    justChangedByDrag = false;
  }

  function onDragStart(e: PointerEvent) {
    e.preventDefault();
    if ($disabled.get()) return;
    $$dragStart.set(e.pageX);
    window.addEventListener("pointerup", onDragEnd);
    window.addEventListener("pointermove", onDragMove);
  }

  function onDragMove(e: PointerEvent) {
    e.preventDefault();
    if ($disabled.get()) return;
    $$dragOffset.set(e.pageX - $$dragStart.get());
  }

  function onDragEnd() {
    if ($disabled.get()) return;

    const pos = clamp($value.get() ? 22 : 0 + $$dragOffset.get(), 0, 22);

    $$dragOffset.set(0);

    window.removeEventListener("pointerup", onDragEnd);
    window.removeEventListener("pointermove", onDragMove);

    const checked = $value.get();
    if (!checked && pos >= 11) {
      props.onChange?.(true);
    } else if (checked && pos < 11) {
      props.onChange?.(false);
    }

    $$isPressed.set(false);
    justChangedByDrag = true;
  }

  return (
    <div
      class={{
        [styles.container]: true,
        [styles.pressed]: $$isPressed,
        [styles.disabled]: $disabled,
        [styles.isOn]: $value,
      }}
      onPointerDown={onPointerDown}
    >
      <label class={styles.label} for={$id} />
      <input
        id={$id}
        class={styles.input}
        checked={$value}
        disabled={$disabled}
        onchange={() => {
          if (justChangedByDrag) {
            justChangedByDrag = false;
            return;
          }
          props.onChange?.(!$value.get());
        }}
        type="checkbox"
      />
      <div class={styles.track} />
      <span
        class={styles.switch}
        style={{
          transform: $($value, $$dragOffset, (v, d) => `translateX(${clamp((v ? 22 : 0) + d, 0, 22)}px)`),
        }}
      >
        {cond(
          $($value, $$dragOffset, (v, d) => clamp((v ? 22 : 0) + d, 0, 22) >= 11),
          props.onIcon ?? <CheckIcon />,
          cond($disabled, <CloseIcon />, props.offIcon ?? <ArrowRightIcon />),
        )}
      </span>
    </div>
  );
}

function clamp(value: number, min: number, max: number) {
  return Math.max(min, Math.min(max, value));
}
