export default {
  "layout": "Search_layout_ba4e8",
  "header": "Search_header_ba4e8",
  "textInput": "Search_textInput_ba4e8",
  "formatOptions": "Search_formatOptions_ba4e8",
  "formatOption": "Search_formatOption_ba4e8",
  "formatIcon": "Search_formatIcon_ba4e8",
  "results": "Search_results_ba4e8",
  "resultContent": "Search_resultContent_ba4e8",
  "noResults": "Search_noResults_ba4e8",
  "userScopeCharm": "Search_userScopeCharm_ba4e8"
};import "ni:sha-256;0sOTHE9pgxcL0DRoUg9y2Yz27OqIeSnD1JWYmR5-xJY";