export default {
  "button": "IconButton_button_3bbc2",
  "active": "IconButton_active_3bbc2",
  "icon": "IconButton_icon_3bbc2",
  "numberBadge": "IconButton_numberBadge_3bbc2",
  "small": "IconButton_small_3bbc2",
  "filled": "IconButton_filled_3bbc2",
  "destructive": "IconButton_destructive_3bbc2",
  "transparent": "IconButton_transparent_3bbc2",
  "withLabel": "IconButton_withLabel_3bbc2",
  "label": "IconButton_label_3bbc2"
};import "ni:sha-256;gPjFCO2JhS-UaNNOCv_jskYbOBp0BFB7tMlvRWIVqvE";