import { $, type Readable, type ViewContext } from "@manyducks.co/dolla";
import { type Renderable } from "@manyducks.co/dolla/lib/types";
import styles from "./CardDetailStack.module.css";
import { BreakpointStore } from "@stores/BreakpointStore";

interface CardDetailStackProps {
  showDetail: boolean | Readable<boolean>;
  listContent: Renderable;
  detailContent: Renderable;

  /** Minimum container width to display contents in expanded mode. */
  expandedThreshold?: number | Readable<number>;

  expandedListWidth?: number | Readable<number>;
}

/**
 * Implements a navigation stack for a list view and a detail view with an animated transition.
 */
export function CardDetailStack(props: CardDetailStackProps, ctx: ViewContext) {
  const breakpoint = ctx.getStore(BreakpointStore);

  const $expandedBreakpoint = $(breakpoint.$width, props.expandedThreshold, (width, threshold) => {
    return threshold != null && width > threshold;
  });

  const $showDetail = $(props.showDetail, $expandedBreakpoint, (show, expanded) => show || expanded);

  return (
    <div
      class={[styles.layout, { [styles.expanded]: $expandedBreakpoint }]}
      style={{ "--expanded-list-width": $(props.expandedListWidth, (value) => `${value ?? 400}px`) }}
    >
      <div class={[styles.frame, styles.listFrame, { [styles.hidden]: props.showDetail }]}>
        {props.listContent}
      </div>
      <div class={[styles.shade, { [styles.show]: props.showDetail }]} />
      <div class={[styles.frame, styles.detailFrame, { [styles.show]: props.showDetail }]}>
        {props.detailContent}
      </div>
    </div>
  );
}
