export default {
  "container": "Card_container_8bb2d",
  "outOfViewport": "Card_outOfViewport_8bb2d",
  "lifted": "Card_lifted_8bb2d",
  "card": "Card_card_8bb2d",
  "scope": "Card_scope_8bb2d",
  "scopeIcon": "Card_scopeIcon_8bb2d",
  "scopeLabel": "Card_scopeLabel_8bb2d",
  "scopeRole": "Card_scopeRole_8bb2d",
  "roleIcon": "Card_roleIcon_8bb2d",
  "roleLabel": "Card_roleLabel_8bb2d",
  "header": "Card_header_8bb2d",
  "contacted": "Card_contacted_8bb2d",
  "headerContent": "Card_headerContent_8bb2d",
  "headerGroup": "Card_headerGroup_8bb2d",
  "titleGroup": "Card_titleGroup_8bb2d",
  "icon": "Card_icon_8bb2d",
  "title": "Card_title_8bb2d",
  "mainTitle": "Card_mainTitle_8bb2d",
  "subtitle": "Card_subtitle_8bb2d",
  "showSubtitle": "Card_showSubtitle_8bb2d",
  "outlet": "Card_outlet_8bb2d",
  "menuPixelIcon": "Card_menuPixelIcon_8bb2d",
  "cardContent": "Card_cardContent_8bb2d",
  "cardToolbar": "Card_cardToolbar_8bb2d",
  "cardToolbarContent": "Card_cardToolbarContent_8bb2d",
  "cardWidget": "Card_cardWidget_8bb2d"
};import "ni:sha-256;6ZbtH4YsL3mSVjDAZDc0H7C5ZHtmZ0eGligPn6KL2_A";