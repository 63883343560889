import { $, $$, DialogStore, LanguageStore, RouterStore, cond, type ViewContext } from "@manyducks.co/dolla";
import { AuthStore } from "@stores/AuthStore";
import { BreakpointStore } from "@stores/BreakpointStore";
import { ChatStore } from "@stores/ChatStore";
import { IOStore } from "@stores/IOStore";
import { LoaderStore } from "@stores/LoaderStore";
import { NavStore } from "@stores/NavStore";
import { NotesStore } from "@stores/NotesStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { TasksStore } from "@stores/TasksStore";
import { ThemeStore } from "@stores/ThemeStore";
import ChatIcon from "@views/@icons/Chat";
import CloseIcon from "@views/@icons/Close";
import MenuIcon from "@views/@icons/Menu";
import SearchIcon from "@views/@icons/Search";
import SettingsIcon from "@views/@icons/Settings";
import { FileViewer } from "@views/FileViewer";
import { TaskEditDialog } from "@views/TaskListItem/TaskEditDialog/TaskEditDialog";
import { Chat } from "./Chat";
import { Places } from "./Nav/Places/Places";
import { Search } from "./Nav/Search/Search";
import { Settings } from "./Nav/Settings/Settings";
import styles from "./Workspace.module.css";
import ChatLineIcon from "@views/@icons/ChatLine";
import PlacesIcon from "@views/@icons/Places";

/**
 * Top level layout for the authenticated portions of the app.
 */
export function Workspace(_: {}, ctx: ViewContext) {
  const router = ctx.getStore(RouterStore);
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);
  const io = ctx.getStore(IOStore);
  const auth = ctx.getStore(AuthStore);
  const projects = ctx.getStore(ProjectsStore);
  const loader = ctx.getStore(LoaderStore);
  const notes = ctx.getStore(NotesStore);
  const tasks = ctx.getStore(TasksStore);
  const chat = ctx.getStore(ChatStore);
  const breakpoint = ctx.getStore(BreakpointStore);
  const theme = ctx.getStore(ThemeStore);
  const dialog = ctx.getStore(DialogStore);
  const nav = ctx.getStore(NavStore);

  const $$menuWidth = $$(Number(localStorage.getItem("ui.menuWidth") ?? 300));
  const $$chatWidth = $$(Number(localStorage.getItem("ui.chatWidth") ?? 300));

  const $$resizeTarget = $$<"menu" | "chat" | null>(null);

  ctx.observe($$menuWidth, (menuWidth) => {
    localStorage.setItem("ui.menuWidth", String(menuWidth));
  });

  ctx.observe($$chatWidth, (chatWidth) => {
    localStorage.setItem("ui.chatWidth", String(chatWidth));
  });

  const $placeColor = $(
    router.$pattern,
    router.$params,
    projects.$cache,
    auth.$me,
    (pattern, params, projects, me) => {
      let color: string | undefined;

      if (pattern?.startsWith("/projects")) {
        const project = params.projectId
          ? projects.find((p) => p.id === Number(params.projectId))
          : undefined;

        color = project?.color ?? "#888";
      } else {
        color = me?.color ?? "#888";
      }

      return color;
    },
  );
  const $chatProject = $(chat.$$projectId, projects.$cache, (projectId, projects) => {
    return projects.find((p) => p.id === projectId);
  });
  const $chatColor = $($chatProject, $placeColor, (project, fallbackColor) => {
    return project?.color ?? fallbackColor;
  });

  ctx.observe($currentLanguage, (language) => {
    ctx.log({ language });
    if (language) {
      localStorage.setItem("quack-current-language", language);
    }
  });

  function onServiceWorkerMessage(this: ServiceWorkerContainer, event: MessageEvent<any>): any {
    ctx.info("received from worker", event.data);

    if (event.data.type === "chatMessage") {
      const projectId = event.data.project.id as number;
      chat.$$projectId.set(projectId);
      nav.openNavCard("chat");
    }
  }

  const $$socketConnected = $$(false);
  const $$isLoaded = $$(false);

  const $$buttonWasClickedDuringThisHover = $$(false);
  const $mainContentIsOverlaid = $(
    breakpoint.$width,
    nav.$leftNavIsOpen,
    nav.$leftNavIsPersistent,
    nav.$rightNavIsOpen,
    nav.$rightNavIsPersistent,
    (width, leftOpen, leftPersistent, rightOpen, rightPersistent) =>
      (leftOpen && (!leftPersistent || width < 800)) || (rightOpen && (!rightPersistent || width < 800)),
  );

  ctx.beforeConnect(async () => {
    if (!auth.$isLoggedIn.get()) {
      router.navigate("/login");
      loader.hideAppLoader();
      return;
    }

    if (router.$path.get() === "/") {
      const lastVisited = localStorage.getItem("nav.lastVisitedPath");
      if (lastVisited) {
        router.navigate(lastVisited);
      } else {
        router.navigate("/my-tasks");
      }
    }

    ctx.observe(router.$path, (path) => {
      localStorage.setItem("nav.lastVisitedPath", path);
    });

    // Once user becomes logged out, drop them back at the landing page.
    ctx.observe(auth.$isLoggedIn, (isLoggedIn) => {
      if (!isLoggedIn) {
        router.navigate("/login");
      }
    });

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", onServiceWorkerMessage);
    }

    await auth.getUsers();

    const socket = io.socket();

    socket.on("connect", () => {
      auth.getUpdateStatus();
      $$socketConnected.set(true);
    });

    socket.on("disconnect", () => {
      $$socketConnected.set(false);
    });

    socket.on("task:update", tasks.taskUpdateReceived);
    socket.on("task:delete", tasks.taskDeleteReceived);

    socket.on("note:update", notes.noteUpdateReceived);
    socket.on("note:delete", notes.noteDeleteReceived);

    // socket.on("settings:update", auth.settingsUpdateReceived);

    socket.on("project:invited", projects.projectInviteReceived); // When a project invite is received
    socket.on("project:added", (projectId) => {}); // When user joins a project
    socket.on("project:update", projects.projectUpdateReceived);
    socket.on("project:delete", (projectId) => {
      projects.projectDeleteReceived(projectId);
      socket.emit("projects:refresh", () => {
        ctx.log("Projects refreshed");
      });
    });

    socket.on("user:update", auth.userUpdateReceived);

    socket.on("chat:message", chat.chatMessageReceived);

    await Promise.all([projects.refreshList(), chat.fetchMessages(), nav.fetchFavorites()]).then(() => {
      loader.hideAppLoader();
      $$isLoaded.set(true);
    });

    ctx.onDisconnected(() => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener("message", onServiceWorkerMessage);
      }
      socket.close();
    });
  });

  ctx.observe($$socketConnected, async (socketConnected) => {
    if (!socketConnected || !$$isLoaded.get()) return;

    const promises: Promise<any>[] = [
      auth.getUsers(),
      projects.refreshList(),
      chat.fetchMessages(),
      nav.fetchFavorites(),
    ];

    // remove deleted resources
    const caches: {
      users: number[];
      projects: number[];
      notes: number[];
      tasks: number[];
    } = {
      users: auth.$users.get().map((x) => x.id),
      projects: projects.$cache.get().map((x) => x.id),
      notes: [],
      tasks: [],
    };

    const pattern = router.$pattern.get();
    const params = router.$params.get();

    const inNotes = pattern?.startsWith("/projects/{#projectId}/notes");
    const inTasks = pattern?.startsWith("/projects/{#projectId}/tasks");

    if (inNotes) {
      caches.notes = [...notes.$cache.get().keys()];
    } else if (inTasks) {
      caches.tasks = [...tasks.$cache.get().keys()];
    }

    const deletedCaches = await auth.validateCache(caches);

    auth.updateCache(deletedCaches.users);
    projects.updateCache(deletedCaches.projects);
    notes.updateCache(deletedCaches.notes);
    tasks.updateCache(deletedCaches.tasks);

    // load latest resources
    if (inNotes) {
      promises.push(notes.fetchIndexFor(Number(params.projectId), true));
    } else if (inTasks) {
      promises.push(tasks.fetchIndexFor(Number(params.projectId), true));
    } else if (pattern?.startsWith("/my-tasks")) {
      promises.push(tasks.fetchToDos());
    }

    await Promise.all(promises);

    ctx.log("Socket reconnected: data refreshed");
  });

  // check for updates
  const onVisibilityChange = async () => {
    if (document.visibilityState === "visible") {
      // clear notifications
      navigator.serviceWorker.ready.then((reg) => {
        reg.getNotifications().then((notifications) => {
          for (let i = 0; i < notifications.length; i += 1) {
            notifications[i].close();
          }
        });
      });

      // check for update
      const updateAvailable = await auth.getUpdateStatus();
      console.log(`UPDATE AVAILABLE: ${updateAvailable}`);
    }
  };

  ctx.onConnected(() => {
    window.addEventListener("visibilitychange", onVisibilityChange);
  });

  ctx.onDisconnected(() => {
    window.removeEventListener("visibilitychange", onVisibilityChange);
  });

  return cond(
    $$isLoaded,
    <div
      class={styles.workspace}
      style={$(
        theme.getThemeVariables$($placeColor),
        $$menuWidth,
        $$chatWidth,
        (themeVars, leftWidth, rightWidth) => {
          return {
            ...themeVars,
            "--left-nav-width": leftWidth + "px",
            "--right-nav-width": rightWidth + "px",
          };
        },
      )}
      onPointerMove={(e) => {
        const target = $$resizeTarget.get();
        if (!target) return;

        e.preventDefault();
        if (target === "menu") {
          $$menuWidth.set(Math.min(500, Math.max(240, e.clientX)));
        } else if (target === "chat") {
          $$chatWidth.set(Math.min(500, Math.max(300, window.innerWidth - e.clientX)));
        }
      }}
      onPointerUp={() => {
        $$resizeTarget.set(null);
      }}
    >
      <div
        class={styles.geometryLayer}
        style={{ backgroundImage: "url(/textures/inspiration-geometry.png)" }}
      />
      <div class={styles.gradientLayer} />

      <div class={styles.navLayer}>
        <div class={styles.navCards}>
          <button
            class={[
              styles.navCardShade,
              {
                // Shade is only visible while a nav card is in overlay mode.
                [styles.visible]: $mainContentIsOverlaid,
              },
            ]}
            onClick={() => {
              nav.closeNavCards();
            }}
            onPointerEnter={() => {
              ctx.log("pointer entered card shade");

              if (nav.$rightNavIsOpen.get() && !nav.$rightNavIsPersistent.get()) {
                nav.closeRightNavCard();
              }

              if (nav.$leftNavIsOpen.get() && !nav.$leftNavIsPersistent.get()) {
                nav.closeLeftNavCard();
              }
            }}
          />

          <div
            class={[
              styles.navCard,
              styles.left,
              { [styles.open]: nav.$leftNavIsOpen, [styles.persistent]: nav.$leftNavIsPersistent },
            ]}
          >
            <div class={styles.navCardContent}>
              <div class={styles.navCardScrollContainer}>
                {$(nav.$latestLeftNavCard, (value) => {
                  if (value === "places") {
                    return (
                      <Places
                        onNavigate={() => {
                          if (!nav.$leftNavIsPersistent.get() || breakpoint.$width.get() < 800) {
                            nav.closeNavCard("places");
                          }
                        }}
                      />
                    );
                  }

                  if (value === "search") {
                    return (
                      <Search
                        onSelected={(result) => {
                          switch (result.type) {
                            case "task":
                              router.navigate(`/projects/${result.data.projectId}/tasks`);
                              setTimeout(() => {
                                const project = projects.$cache
                                  .get()
                                  .find((p) => p.id === result.data.projectId);
                                dialog.open(TaskEditDialog, {
                                  projectId: result.data.projectId,
                                  task: result.data,
                                  color: project?.color,
                                });
                                if (!nav.$leftNavIsPersistent.get()) {
                                  nav.closeNavCard("search");
                                }
                              }, 300);
                              break;
                            case "note":
                              router.navigate(`/projects/${result.data.projectId}/notes/${result.data.id}`);
                              if (!nav.$leftNavIsPersistent.get()) {
                                nav.closeNavCard("search");
                              }
                              break;
                            default:
                              if (!nav.$leftNavIsPersistent.get()) {
                                nav.closeNavCard("search");
                              }
                              break;
                          }

                          ctx.log("Selected", result);
                        }}
                      />
                    );
                  }

                  if (value === "settings") {
                    return <Settings />;
                  }

                  return null;
                })}
              </div>
            </div>
          </div>

          <div
            class={[
              styles.navCard,
              styles.right,
              { [styles.open]: nav.$rightNavIsOpen, [styles.persistent]: nav.$rightNavIsPersistent },
            ]}
            style={theme.getThemeVariables$($chatColor)}
          >
            <div class={styles.navCardContent}>
              <div class={styles.navCardScrollContainer}>
                <Chat
                  onShowSettings={() => {
                    nav.openNavCard("settings", true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div class={styles.navRails}>
          <nav class={[styles.navRail, styles.left]}>
            <ul class={styles.navList}>
              <li>
                <button
                  class={[styles.navButton, { [styles.active]: nav.$placeMenuIsOpen }]}
                  onPointerEnter={() => {
                    if (
                      !$$buttonWasClickedDuringThisHover.get() &&
                      !nav.$leftNavIsPersistent.get() &&
                      !nav.$placeMenuIsOpen.get()
                    ) {
                      // Open in overlay mode.
                      nav.openNavCard("places", false);
                    }
                  }}
                  onPointerLeave={() => {
                    // Clear state and allow pointerenter to fire again.
                    $$buttonWasClickedDuringThisHover.set(false);
                  }}
                  onClick={() => {
                    // Toggle into persistent mode.
                    nav.toggleNavCard("places", true);
                    $$buttonWasClickedDuringThisHover.set(true);
                  }}
                >
                  <div class={styles.navButtonCircle}>
                    {cond(
                      $(
                        nav.$placeMenuIsOpen,
                        nav.$leftNavIsPersistent,
                        (open, persistent) => open && persistent,
                      ),
                      <CloseIcon />,
                      <PlacesIcon />,
                    )}
                  </div>
                </button>
              </li>

              <li>
                <button
                  class={[styles.navButton, { [styles.active]: nav.$searchIsOpen }]}
                  onPointerEnter={() => {
                    if (
                      !$$buttonWasClickedDuringThisHover.get() &&
                      !nav.$leftNavIsPersistent.get() &&
                      !nav.$searchIsOpen.get()
                    ) {
                      // Open in overlay mode.
                      nav.openNavCard("search", false);
                    }
                  }}
                  onPointerLeave={() => {
                    // Clear state and allow pointerenter to fire again.
                    $$buttonWasClickedDuringThisHover.set(false);
                  }}
                  onClick={() => {
                    // Toggle into persistent mode.
                    nav.toggleNavCard("search", true);
                    $$buttonWasClickedDuringThisHover.set(true);
                  }}
                >
                  <div class={styles.navButtonCircle}>
                    {cond(
                      $(
                        nav.$searchIsOpen,
                        nav.$leftNavIsPersistent,
                        (open, persistent) => open && persistent,
                      ),
                      <CloseIcon />,
                      <SearchIcon />,
                    )}
                  </div>
                </button>
              </li>

              <li>
                <button
                  class={[styles.navButton, { [styles.active]: nav.$settingsIsOpen }]}
                  onPointerEnter={() => {
                    if (
                      !$$buttonWasClickedDuringThisHover.get() &&
                      !nav.$leftNavIsPersistent.get() &&
                      !nav.$settingsIsOpen.get()
                    ) {
                      // Open in overlay mode.
                      nav.openNavCard("settings", false);
                    }
                  }}
                  onPointerLeave={() => {
                    // Clear state and allow pointerenter to fire again.
                    $$buttonWasClickedDuringThisHover.set(false);
                  }}
                  onClick={() => {
                    // Toggle into persistent mode.
                    nav.toggleNavCard("settings", true);
                    $$buttonWasClickedDuringThisHover.set(true);
                  }}
                >
                  <div class={styles.navButtonCircle}>
                    {cond(
                      $(
                        nav.$settingsIsOpen,
                        nav.$leftNavIsPersistent,
                        (open, persistent) => open && persistent,
                      ),
                      <CloseIcon />,
                      <SettingsIcon />,
                    )}
                  </div>
                </button>
              </li>
            </ul>
          </nav>

          <nav class={[styles.navRail, styles.right]}>
            <ul class={styles.navList}>
              <li>
                <button
                  class={[
                    styles.navButton,
                    styles.chatButton,
                    {
                      [styles.active]: nav.$chatIsOpen,
                      [styles.persistent]: $(
                        nav.$chatIsOpen,
                        nav.$rightNavIsPersistent,
                        (open, persistent) => open && persistent,
                      ),
                    },
                  ]}
                  style={$(theme.getThemeVariables$($chatColor), nav.$chatIsOpen, (themeVars, open) =>
                    open ? themeVars : {},
                  )}
                  onPointerEnter={() => {
                    if (!$$buttonWasClickedDuringThisHover.get() && !nav.$chatIsOpen.get()) {
                      // Open in overlay mode.
                      nav.openNavCard("chat", false);
                    }
                  }}
                  onPointerLeave={() => {
                    // Clear state and allow pointerenter to fire again.
                    $$buttonWasClickedDuringThisHover.set(false);
                  }}
                  onClick={() => {
                    // Toggle into persistent mode.
                    nav.toggleNavCard("chat", true);
                    $$buttonWasClickedDuringThisHover.set(true);
                  }}
                >
                  <div class={styles.navButtonCircle}>
                    {cond(
                      $(
                        nav.$chatIsOpen,
                        nav.$rightNavIsPersistent,
                        (chatIsOpen, persistent) => chatIsOpen && persistent,
                      ),
                      <CloseIcon />,
                      <ChatLineIcon />,
                    )}
                  </div>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div
        class={[
          styles.layout,
          {
            [styles.overlaid]: $mainContentIsOverlaid,
            [styles.leftNavPersistent]: nav.$leftNavIsPersistent,
            [styles.rightNavPersistent]: nav.$rightNavIsPersistent,
          },
        ]}
        style={theme.getThemeVariables$($placeColor)}
      >
        <div class={styles.main}>{ctx.outlet()}</div>
      </div>

      <div
        class={[
          styles.grabber,
          {
            [styles.active]: $(
              nav.$leftNavIsPersistent,
              breakpoint.$width,
              (persistent, width) => persistent && width > 920,
            ),
            [styles.grabbed]: $($$resizeTarget, (target) => target === "menu"),
          },
        ]}
        style={{ transform: $($$menuWidth, (value) => `translateX(${value - 7}px)`) }}
        onPointerDown={() => {
          $$resizeTarget.set("menu");
        }}
      />
      <div
        class={[
          styles.grabber,
          {
            [styles.active]: $(
              nav.$rightNavIsPersistent,
              breakpoint.$width,
              (persistent, width) => persistent && width > 920,
            ),
            [styles.grabbed]: $($$resizeTarget, (target) => target === "chat"),
          },
        ]}
        style={{ transform: $($$chatWidth, (value) => `translateX(calc(100vw - ${value + 5}px))`) }}
        onPointerDown={() => {
          $$resizeTarget.set("chat");
        }}
      />

      {cond(nav.$fileViewerIsOpen, <FileViewer />)}
    </div>,
  );
}
