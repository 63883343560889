export default {
  "calendar": "CalendarView_calendar_76a7e",
  "header": "CalendarView_header_76a7e",
  "days": "CalendarView_days_76a7e",
  "day": "CalendarView_day_76a7e",
  "notInMonth": "CalendarView_notInMonth_76a7e",
  "isToday": "CalendarView_isToday_76a7e",
  "isSelected": "CalendarView_isSelected_76a7e",
  "dayButton": "CalendarView_dayButton_76a7e",
  "dayHeader": "CalendarView_dayHeader_76a7e",
  "dayMonthLabel": "CalendarView_dayMonthLabel_76a7e",
  "dayItems": "CalendarView_dayItems_76a7e",
  "dayItem": "CalendarView_dayItem_76a7e",
  "completed": "CalendarView_completed_76a7e",
  "hiddenMessage": "CalendarView_hiddenMessage_76a7e",
  "daySection": "CalendarView_daySection_76a7e",
  "empty": "CalendarView_empty_76a7e"
};import "ni:sha-256;wjqGlM51jIWPAvNgKK2m_H4si9w7dEFk3F0r_m31A_M";