export default {
  "container": "Details_container_73263",
  "emptyPlaceholder": "Details_emptyPlaceholder_73263",
  "meta": "Details_meta_73263",
  "metaButton": "Details_metaButton_73263",
  "active": "Details_active_73263",
  "open": "Details_open_73263",
  "metaButtonIcon": "Details_metaButtonIcon_73263",
  "metaButtonLabel": "Details_metaButtonLabel_73263",
  "metaContent": "Details_metaContent_73263",
  "metaSection": "Details_metaSection_73263",
  "metaSubSection": "Details_metaSubSection_73263",
  "metaAttachmentsList": "Details_metaAttachmentsList_73263",
  "metaAttachmentUpload": "Details_metaAttachmentUpload_73263",
  "metaAttachmentUploadInput": "Details_metaAttachmentUploadInput_73263",
  "metaAttachmentUploadLabel": "Details_metaAttachmentUploadLabel_73263",
  "metaCheckbox": "Details_metaCheckbox_73263",
  "metaCheckboxLabel": "Details_metaCheckboxLabel_73263",
  "timestampList": "Details_timestampList_73263",
  "timestampListItem": "Details_timestampListItem_73263",
  "timestampListItemMain": "Details_timestampListItemMain_73263",
  "timestampListItemSecondary": "Details_timestampListItemSecondary_73263",
  "content": "Details_content_73263",
  "titleAndDescription": "Details_titleAndDescription_73263",
  "titleContainer": "Details_titleContainer_73263",
  "titleInput": "Details_titleInput_73263",
  "titlePlaceholder": "Details_titlePlaceholder_73263",
  "descriptionContainer": "Details_descriptionContainer_73263",
  "descriptionInput": "Details_descriptionInput_73263",
  "descriptionPlaceholder": "Details_descriptionPlaceholder_73263",
  "formGroup": "Details_formGroup_73263",
  "visible": "Details_visible_73263",
  "clearButton": "Details_clearButton_73263",
  "metaPriorityItem": "Details_metaPriorityItem_73263",
  "metaButtons": "Details_metaButtons_73263",
  "hidden": "Details_hidden_73263"
};import "ni:sha-256;NJSmxivUM8Ip7EELxJRNtFdUhUUcdz3R3RO86mn1gcs";