export default {
  "messageList": "ChatMessageList_messageList_c5276",
  "message": "ChatMessageList_message_c5276",
  "mine": "ChatMessageList_mine_c5276",
  "noTextSpacer": "ChatMessageList_noTextSpacer_c5276",
  "authorAvatar": "ChatMessageList_authorAvatar_c5276",
  "authorAvatarSpacer": "ChatMessageList_authorAvatarSpacer_c5276",
  "messageBubble": "ChatMessageList_messageBubble_c5276",
  "messageContent": "ChatMessageList_messageContent_c5276",
  "messageInfo": "ChatMessageList_messageInfo_c5276",
  "authorName": "ChatMessageList_authorName_c5276",
  "messageTimestamp": "ChatMessageList_messageTimestamp_c5276"
};import "ni:sha-256;cjPIMMBB-PqmCpiRAPUej-DFPIsujjpN5y1jnK_PS0s";