import { $, $$, cond, LanguageStore, Readable, repeat, RouterStore, ViewContext } from "@manyducks.co/dolla";
import { ClockStore } from "@stores/ClockStore";
import { TasksStore } from "@stores/TasksStore";
import ArrowUpDoubleLine from "@views/@icons/ArrowUpDoubleLine";
import ArrowUpSingleLine from "@views/@icons/ArrowUpSingleLine";
import Calendar from "@views/@icons/Calendar";
import TagIcon from "@views/@icons/Tag";
import UserIcon from "@views/@icons/User";
import { DatePicker } from "@views/DatePicker";
import { SegmentButton } from "@views/SegmentButton";
import { TagInput } from "@views/TagInput";
import { UserPicker } from "@views/UserPicker";
import { startOfDay, subDays } from "date-fns";
import { Project, Task } from "schemas";
import styles from "./Details.module.css";
import { CardToolbar } from "@views/Card";
import { ToolBar, ToolBarGroup } from "@views/ToolBar";
import { BackButton } from "@views/BackButton";
import { DocMetaMenu } from "@views/DocMeta";
import { relativePastTime } from "@helpers/relativePastTime";
import { sticky } from "@helpers/sticky";
import { Checkbox } from "@views/Checkbox";
import { Point } from "@views/HoverMenu";
import { InputFile } from "Workspace/Chat/ChatEditor/ChatEditor";
import { ProjectsStore } from "@stores/ProjectsStore";
import { AuthStore } from "@stores/AuthStore";
import Paperclip from "@views/@icons/Paperclip";
import Time from "@views/@icons/Time";
import { FileAttachment } from "@views/FileAttachment";
import Plus from "@views/@icons/Plus";
import Edit from "@views/@icons/Edit";

type MetaSection = "attachments" | "tags" | "timestamps";

interface DetailsProps {
  $project: Readable<Project | undefined>;
  $taskId: Readable<number | undefined>;
}

export function Details(props: DetailsProps, ctx: ViewContext) {
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);
  const clock = ctx.getStore(ClockStore);
  const tasks = ctx.getStore(TasksStore);
  const router = ctx.getStore(RouterStore);
  const auth = ctx.getStore(AuthStore);
  const projects = ctx.getStore(ProjectsStore);

  const $currentTask = $(props.$taskId, tasks.$cache, (id, tasks) => (id ? tasks.get(id) : undefined));
  const $latestTask = $($currentTask, sticky());

  // Close dialog if task was deleted.
  // ctx.observe($taskExists, (exists) => {
  //   if (!exists) {
  //     props.$$open.set(false);
  //   }
  // });

  const $$submitting = $$<boolean>(false);

  const task = $currentTask.get();

  const $$title = $$<string>(task?.title ?? "");
  const $$status = $$<string>(task?.status ?? "");
  const $$assignedUserId = $$<number | null>(task?.assignedUserId ?? null);
  const $$priority = $$<number | null>(task?.priority ?? null);
  const $$dueDate = $$<string | null>(task?.dueDate || null);
  const $$tags = $$<string[]>(task?.tags ?? []);

  const $$titleElement = $$<HTMLSpanElement>();
  const $$descriptionElement = $$<HTMLSpanElement>();

  const $$assignedUserEnabled = $$($$assignedUserId.get() != null);
  const $$priorityEnabled = $$($$priority.get() != null);
  const $$dueDateEnabled = $$($$dueDate.get() != null);
  const $$tagsEnabled = $$($$tags.get().length > 0);

  const $projectId = $(props.$project, (n) => n?.id);
  const $tags = $($latestTask, (n) => (n ? [...n.tags].sort() : []));
  const $tagCount = $($tags, (t) => t.length);
  const $attachments = $<any[]>([]);
  // const $attachments = $($latestTask, (n) => n?.attachments ?? []);
  const $attachmentCount = $($attachments, (a) => a.length);

  const $$metaContainer = $$<HTMLElement>();
  const $$openMetaSection = $$<MetaSection>();
  const $$menuAnchor = $$<Point>({ x: 0, y: 0 });
  const $$fileUploads = $$<InputFile[]>([]);

  const $userRole = $($projectId, projects.$cache, auth.$me, (projectId, projectsCache, me) => {
    const project = projectsCache.find((p) => p.id === projectId);
    if (!project || !me) return null;
    const user = project.users.find((u) => u.id === me.id);
    return user?.role ?? null;
  });

  const $isViewer = $($userRole, (role) => role === "viewer");

  function setAnchor(e: Event) {
    const containerRect = $$metaContainer.get()!.getBoundingClientRect();
    const rect = (e.currentTarget as HTMLElement).getBoundingClientRect();

    $$menuAnchor.set({
      x: rect.left + rect.width / 2 - containerRect.left,
      y: rect.top + rect.height / 2 - containerRect.top,
    });
  }

  const $$menuIsOpen = $$(false);

  // Things that can be edited: title, due date, assigned user, tags, status, priority

  ctx.beforeConnect(() => {
    const titleElement = $$titleElement.get();
    if (titleElement) {
      titleElement.textContent = $$title.get();
    }
    const descriptionElement = $$descriptionElement.get();
    if (descriptionElement) {
      descriptionElement.textContent = $$status.get();
    }
  });

  return cond(
    $latestTask,
    <div class={styles.container}>
      <div class={styles.meta} ref={$$metaContainer}>
        {/* Pinned */}

        <div class={styles.metaCheckbox}>
          <Checkbox
            id={`completed_checkbox_${ctx.uniqueId}`}
            $value={$($latestTask, (t) => t?.completedAt != null)}
            onChange={(value) => {
              // const note = $latestTask.get()!;
              // notes.setPinned(note.id, value);
            }}
            disabled={$isViewer}
          />
          <label class={styles.metaCheckboxLabel} for={`pinned_checkbox_${ctx.uniqueId}`}>
            {translate("workspace.project.notes.details.meta.pinnedLabel")}
          </label>
        </div>

        {/* Attachments */}

        <button
          class={[
            styles.metaButton,
            {
              [styles.active]: $attachmentCount,
              [styles.open]: $($$openMetaSection, (s) => s === "attachments"),
            },
          ]}
          title={$($attachments, (attachments) => {
            if (attachments.length > 0) {
              return translate("workspace.project.notes.details.meta.attachmentsLabel_value", {
                value: attachments.length,
              });
            } else {
              return translate("workspace.project.notes.details.meta.attachmentsLabel_empty");
            }
          })}
          disabled={$isViewer}
          onClick={(e) => {
            setAnchor(e);
            $$openMetaSection.update((current) => (current === "attachments" ? undefined : "attachments"));
          }}
        >
          <div class={styles.metaButtonIcon}>
            <Paperclip />
          </div>
          {cond($attachmentCount, <span class={styles.metaButtonLabel}>{$attachmentCount}</span>)}
        </button>

        {/* Tags */}

        <button
          class={[
            styles.metaButton,
            {
              [styles.active]: $tagCount,
              [styles.open]: $($$openMetaSection, (s) => s === "tags"),
            },
          ]}
          title={$($tags, (tags) => {
            if (tags.length > 0) {
              return translate("workspace.project.notes.details.meta.tagsLabel_value", {
                value: tags.join(", "),
              });
            } else {
              return translate("workspace.project.notes.details.meta.tagsLabel_empty");
            }
          })}
          disabled={$isViewer}
          onClick={(e) => {
            setAnchor(e);
            $$openMetaSection.update((current) => (current === "tags" ? undefined : "tags"));
          }}
        >
          <div class={styles.metaButtonIcon}>
            <TagIcon />
          </div>
          {cond($tagCount, <span class={styles.metaButtonLabel}>{$tagCount}</span>)}
        </button>

        {/* Timestamps */}

        <button
          class={[styles.metaButton, { [styles.open]: $($$openMetaSection, (s) => s === "timestamps") }]}
          title={translate("workspace.project.notes.details.meta.timestampsLabel")}
          onClick={(e) => {
            setAnchor(e);
            $$openMetaSection.update((current) => (current === "timestamps" ? undefined : "timestamps"));
          }}
        >
          <div class={styles.metaButtonIcon}>
            <Time />
          </div>
        </button>
      </div>

      {/* Menu: Attachments */}

      <DocMetaMenu
        $anchorPoint={$$menuAnchor}
        $open={$($$openMetaSection, (s) => s === "attachments")}
        onClose={() => {
          $$openMetaSection.set(undefined);
        }}
      >
        <section class={[styles.metaSection, styles.metaAttachmentsSection]}>
          <label>{translate("workspace.project.notes.details.meta.attachmentsTitle")}</label>
          <ul class={styles.metaAttachmentsList}>
            {repeat(
              $attachments,
              (a) => a.id,
              ($a) => (
                <li>
                  <FileAttachment
                    $attachment={$a}
                    onDelete={(file) => {
                      // const task = $latestTask.get()!;
                      // http.delete(`/api/tasks/${task.id}/attachments/${file.id}`);
                    }}
                  />
                </li>
              ),
            )}
          </ul>
          <div class={styles.metaAttachmentUpload}>
            <input
              id="attachmentUploadInput"
              class={styles.metaAttachmentUploadInput}
              type="file"
              multiple
              onChange={(e) => {
                const target = e.currentTarget as HTMLInputElement;
                const files = Array.from(target.files ?? []);

                // addFiles(files);
              }}
            />
            <label for="attachmentUploadInput" class={styles.metaAttachmentUploadLabel}>
              {translate("workspace.project.notes.details.meta.attachmentsUploadLabel")}
            </label>
          </div>
        </section>
      </DocMetaMenu>

      {/* Menu: Tags */}

      {/* {cond(
      $latestTask,
      <DocMetaMenu
        $anchorPoint={$$menuAnchor}
        $open={$($$openMetaSection, (s) => s === "tags")}
        onClose={() => {
          $$openMetaSection.set(undefined);
        }}
      >
        <section class={styles.metaSection}>
          <label>{translate("workspace.project.notes.details.meta.tagsTitle")}</label>
          <TagInput
            $value={$tags}
            onChange={({ tags }) => {
              const note = $latestNote.get()!;
              notes.setTags(note.id, tags);
            }}
          />
        </section>
      </DocMetaMenu>,
    )} */}

      {/* Menu: Timestamps */}

      <DocMetaMenu
        $anchorPoint={$$menuAnchor}
        $open={$($$openMetaSection, (s) => s === "timestamps")}
        onClose={() => {
          $$openMetaSection.set(undefined);
        }}
      >
        <section class={styles.metaSection}>
          <ul class={styles.timestampList}>
            <li class={styles.timestampListItem}>
              <div class={styles.timestampListItemMain}>
                <Plus />
                <span>
                  {$($latestTask, $currentLanguage, (task, lang) => {
                    const timestamp = relativePastTime(lang!, task!.createdAt);
                    if (timestamp) {
                      return translate("relativeTime.createdAtTimestamp", { timestamp }).get();
                    } else {
                      return translate("relativeTime.createdNow").get();
                    }
                  })}
                </span>
              </div>
              <span class={styles.timestampListItemSecondary}>
                {$($latestTask, $currentLanguage, (task, lang) => {
                  return formatTimestamp(task!.createdAt, lang!);
                })}
              </span>
            </li>

            <li class={[styles.timestampListItem]}>
              <div class={styles.timestampListItemMain}>
                <Edit />
                <span>
                  {$($latestTask, $currentLanguage, (task, lang) => {
                    const timestamp = relativePastTime(lang!, task!.updatedAt);
                    if (timestamp) {
                      return translate("relativeTime.updatedAtTimestamp", { timestamp }).get();
                    } else {
                      return translate("relativeTime.updatedNow").get();
                    }
                  })}
                </span>
              </div>
              <span class={styles.timestampListItemSecondary}>
                {$($latestTask, $currentLanguage, (task, lang) => {
                  return formatTimestamp(task!.updatedAt, lang!);
                })}
              </span>
            </li>
          </ul>
        </section>
      </DocMetaMenu>

      <div class={styles.content}>
        <section class={styles.titleAndDescription}>
          <div class={styles.titleContainer}>
            {cond(
              $($$title, (x) => x.trim().length === 0),
              <span class={styles.titlePlaceholder}>
                {translate("views.taskListItem.editDialog.titlePlaceholder")}
              </span>,
            )}
            <span
              ref={$$titleElement}
              class={styles.titleInput}
              contentEditable
              role="textbox"
              onInput={(e) => {
                const value = (e.currentTarget as HTMLElement).textContent ?? "";
                $$title.set(value);
              }}
            ></span>
          </div>

          <div class={styles.descriptionContainer}>
            {cond(
              $($$status, (x) => x.trim().length === 0),
              <span class={styles.descriptionPlaceholder}>
                {translate("views.taskListItem.editDialog.descriptionPlaceholder")}
              </span>,
            )}
            <span
              ref={$$descriptionElement}
              class={styles.descriptionInput}
              contentEditable
              role="textbox"
              onInput={(e) => {
                const value = (e.currentTarget as HTMLElement).textContent ?? "";
                $$status.set(value);
              }}
            ></span>
          </div>
        </section>

        <section class={[styles.formGroup, { [styles.visible]: $$assignedUserEnabled }]}>
          <header>
            <label>{translate("views.taskListItem.editDialog.fields.assign.label")}</label>
            <button
              type="button"
              class={styles.clearButton}
              onClick={() => {
                $$assignedUserId.set(null);
                $$assignedUserEnabled.set(false);
              }}
            >
              {translate("views.taskListItem.editDialog.clearLabel")}
            </button>
          </header>

          <UserPicker
            $selectedId={$($$assignedUserId, (id) => id ?? undefined)}
            $projectId={$(props.$project, (p) => p!.id)}
            onchange={(newUserId) => {
              $$assignedUserId.set(newUserId ?? null);
            }}
          />
        </section>

        <section class={[styles.formGroup, { [styles.visible]: $$dueDateEnabled }]}>
          <header>
            <label>{translate("views.taskListItem.editDialog.fields.dueDate.label")}</label>
            <button
              type="button"
              class={styles.clearButton}
              onClick={() => {
                $$dueDate.set(null);
                $$dueDateEnabled.set(false);
              }}
            >
              {translate("views.taskListItem.editDialog.clearLabel")}
            </button>
          </header>

          <DatePicker
            $value={$$dueDate}
            $min={$(clock.$hour, () => startOfDay(subDays(new Date(), 1)))}
            onChange={(value) => {
              $$dueDate.set(value);
            }}
            accentColor={$(props.$project, (p) => p!.color)}
            placeholder="---"
          />
        </section>

        <section class={[styles.formGroup, { [styles.visible]: $$priorityEnabled }]}>
          <header>
            <label>{translate("views.taskListItem.editDialog.fields.priority.label")}</label>
            <button
              type="button"
              class={styles.clearButton}
              onClick={() => {
                $$priority.set(null);
                $$priorityEnabled.set(false);
              }}
            >
              {translate("views.taskListItem.editDialog.clearLabel")}
            </button>
          </header>

          <SegmentButton
            value={$$priority}
            onChange={(value) => {
              $$priority.set(value);
            }}
            segments={[
              {
                value: 2,
                content: (
                  <div class={styles.metaPriorityItem}>
                    <ArrowUpDoubleLine />
                    <span>{translate("views.taskListItem.priority.now.title")}</span>
                  </div>
                ),
              },
              {
                value: 1,
                content: (
                  <div class={styles.metaPriorityItem}>
                    <ArrowUpSingleLine />
                    <span>{translate("views.taskListItem.priority.soon.title")}</span>
                  </div>
                ),
              },
              {
                value: null,
                content: (
                  <div class={styles.metaPriorityItem}>
                    <span>{translate("views.taskListItem.priority.none.title")}</span>
                  </div>
                ),
              },
            ]}
          />
        </section>

        <section class={[styles.formGroup, { [styles.visible]: $$tagsEnabled }]}>
          <header>
            <label>{translate("views.taskListItem.editDialog.fields.tags.label")}</label>
            <button
              type="button"
              class={styles.clearButton}
              onClick={() => {
                $$tags.set([]);
                $$tagsEnabled.set(false);
              }}
            >
              {translate("views.taskListItem.editDialog.clearLabel")}
            </button>
          </header>

          <TagInput
            $value={$$tags}
            onChange={({ tags }) => {
              $$tags.set(tags);
            }}
          />
        </section>

        <ul class={styles.metaButtons}>
          <li>
            <button
              type="button"
              class={[styles.metaButton, { [styles.hidden]: $$assignedUserEnabled }]}
              onClick={() => {
                $$assignedUserEnabled.set(true);
              }}
            >
              <div class={styles.metaButtonIcon}>
                <UserIcon />
              </div>
              {translate("views.taskListItem.editDialog.fields.assign.chipText")}
            </button>
          </li>
          <li>
            <button
              type="button"
              class={[styles.metaButton, { [styles.hidden]: $$dueDateEnabled }]}
              onClick={() => {
                $$dueDateEnabled.set(true);
              }}
            >
              <div class={styles.metaButtonIcon}>
                <Calendar />
              </div>
              {translate("views.taskListItem.editDialog.fields.dueDate.chipText")}
            </button>
          </li>
          <li>
            <button
              type="button"
              class={[styles.metaButton, { [styles.hidden]: $$priorityEnabled }]}
              onClick={() => {
                $$priorityEnabled.set(true);
              }}
            >
              <div class={styles.metaButtonIcon}>
                <ArrowUpDoubleLine />
              </div>
              {translate("views.taskListItem.editDialog.fields.priority.chipText")}
            </button>
          </li>
          <li>
            <button
              type="button"
              class={[styles.metaButton, { [styles.hidden]: $$tagsEnabled }]}
              onClick={() => {
                $$tagsEnabled.set(true);
              }}
            >
              <div class={styles.metaButtonIcon}>
                <TagIcon />
              </div>
              {translate("views.taskListItem.editDialog.fields.tags.chipText")}
            </button>
          </li>
        </ul>
      </div>
    </div>,
    <div class={styles.emptyPlaceholder}>Select a task.</div>,
  );
}

function formatTimestamp(date: Date | string, lang: string) {
  return new Intl.DateTimeFormat([lang, "en-US"], { dateStyle: "medium", timeStyle: "short" }).format(
    new Date(date),
  );
}
