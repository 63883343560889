import { Readable, ViewContext } from "@manyducks.co/dolla";
import styles from "./ColorDivider.module.css";

interface ColorDividerProps {
  reverse?: boolean | Readable<boolean>;
}

export function ColorDivider(props: ColorDividerProps, ctx: ViewContext) {
  return <div class={[styles.divider, { [styles.reverse]: props.reverse }]} />;
}
