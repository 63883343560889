import { sortTasks } from "@helpers/sortTasks";
import {
  $,
  $$,
  cond,
  LanguageStore,
  Readable,
  repeat,
  RouterStore,
  Writable,
  type ViewContext,
} from "@manyducks.co/dolla";
import { AuthStore } from "@stores/AuthStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { TasksStore } from "@stores/TasksStore";
import { CardContent, CardToolbar } from "@views/Card";
import { CollapsibleListSection } from "@views/CollapsibleListSection";
import { TagFilter } from "@views/TagFilter";
import { TaskListItem } from "@views/TaskListItem";
import { type Task, type User } from "schemas";
import styles from "./Assigned.module.css";
import { ColorDivider } from "@views/ColorDivider";

interface AssignedProps {
  $projectId: Readable<number>;
  $userRole: Readable<"viewer" | "collaborator" | "admin">;
  $projectColor: Readable<string | undefined>;
  $$filterTags: Writable<string[]>;
}

interface UserTasks {
  user: User;
  tasks: Task[];
}

export function Assigned(props: AssignedProps, ctx: ViewContext) {
  const auth = ctx.getStore(AuthStore);
  const tasks = ctx.getStore(TasksStore);
  const projects = ctx.getStore(ProjectsStore);
  const router = ctx.getStore(RouterStore);
  const { translate } = ctx.getStore(LanguageStore);

  const $project = $(projects.$cache, props.$projectId, (projects, id) => projects.find((p) => p.id === id)!);

  const $tags = $(props.$projectId, tasks.$cache, (projectId, tasks) => {
    const tags: { [tag: string]: number } = {};

    if (projectId != null) {
      for (const task of tasks.values()) {
        if (task.projectId == projectId && task.completedAt == null && task.assignedUserId != null) {
          for (const tag of task.tags) {
            tags[tag] = (tags[tag] ?? 0) + 1;
          }
        }
      }
    }

    return tags;
  });

  const $$collapsedSections = $$<string[]>([]);

  const $canInteract = $(props.$userRole, (role) => role != "viewer");

  const $tasksByUser = $(
    $project,
    auth.$me,
    auth.$users,
    tasks.$cache,
    props.$$filterTags,
    (project, me, users, tasks, tags) => {
      const groups: UserTasks[] = [];
      const usersInProject = users.filter((u) =>
        project?.users.find((pu) => pu.id === u.id && pu.role !== "viewer"),
      );

      for (const user of usersInProject) {
        const group: UserTasks = { user, tasks: [] };
        for (const [id, task] of tasks) {
          if (
            task.projectId === project!.id &&
            task.assignedUserId === user.id &&
            tags.every((t) => task.tags.includes(t))
          ) {
            group.tasks.push(task);
          }
        }
        groups.push(group);
      }

      groups.sort((a, b) => {
        if (a.user.id === me!.id) {
          return -1;
        } else if (b.user.id === me!.id) {
          return +1;
        } else {
          return 0;
        }
      });

      return groups;
    },
  );

  /**
   * Creates a writable that toggles a section open/closed.
   */
  function proxySectionIsOpen$$(section: string | Readable<string>) {
    const $section = $(section);

    return $$($$collapsedSections, {
      get() {
        const section = $section.get();
        return !$$collapsedSections.get().includes(section);
      },
      set(open) {
        const section = $section.get();
        const current = $$collapsedSections.get();

        if (open && current.includes(section)) {
          $$collapsedSections.set(current.filter((x) => x !== section));
        }
        if (!open && !current.includes(section)) {
          $$collapsedSections.set([...current, section]);
        }
      },
    });
  }

  return (
    <div class={styles.container}>
      <CardContent>
        <div class={styles.content}>
          {repeat(
            $tasksByUser,
            (g) => g.user.id,
            ($group) => {
              const $user = $($group, (g) => g.user);
              const $tasks = $($group, (g) => g.tasks);
              const $$open = proxySectionIsOpen$$($($user, (u) => `user:${u.id}`));

              return (
                <CollapsibleListSection
                  $$open={$$open}
                  title={$($user, (u) => u.name)}
                  headerColor={$($user, (u) => u.color)}
                  itemCount={$($tasks, (tasks) => tasks.filter((t) => t.completedAt == null).length)}
                >
                  {cond(
                    $($tasks, (tasks) => tasks.filter((t) => t.completedAt == null).length === 0),
                    <p class={styles.emptyListMessage}>
                      {translate("workspace.project.tasks.assigned.emptyListMessage")}
                    </p>,
                  )}

                  <ul class={styles.taskList}>
                    {repeat(
                      $($tasks, (tasks) => tasks.filter((t) => t.completedAt == null).sort(sortTasks)),
                      (task) => task.id,
                      ($task) => {
                        return (
                          <TaskListItem
                            $task={$task}
                            interactive={$canInteract}
                            // onClick={(e) => {
                            //   e.preventDefault();
                            //   const task = $task.get();
                            //   router.navigate(`/projects/${task.projectId}/tasks/${task.id}`, {
                            //     preserveQuery: true,
                            //   });
                            // }}
                            scrollToTask={() => {}}
                          />
                        );
                      },
                    )}
                  </ul>
                </CollapsibleListSection>
              );
            },
          )}
        </div>
      </CardContent>

      <CardToolbar>
        <div class={styles.tagFilter}>
          <TagFilter $$tags={props.$$filterTags} $color={$($project, (p) => p!.color)} $suggestions={$tags} />
        </div>
      </CardToolbar>
    </div>
  );
}
