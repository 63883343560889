export default {
  "layout": "Settings_layout_5e7b5",
  "content": "Settings_content_5e7b5",
  "formToolBar": "Settings_formToolBar_5e7b5",
  "formGroup": "Settings_formGroup_5e7b5",
  "userName": "Settings_userName_5e7b5",
  "nameForm": "Settings_nameForm_5e7b5",
  "nameFormButtons": "Settings_nameFormButtons_5e7b5",
  "userStatus": "Settings_userStatus_5e7b5",
  "userAvatar": "Settings_userAvatar_5e7b5",
  "userColorInput": "Settings_userColorInput_5e7b5",
  "userColorButtons": "Settings_userColorButtons_5e7b5",
  "editor": "Settings_editor_5e7b5",
  "editorButtons": "Settings_editorButtons_5e7b5",
  "formLabel": "Settings_formLabel_5e7b5",
  "sectionHeader": "Settings_sectionHeader_5e7b5",
  "headerTitle": "Settings_headerTitle_5e7b5",
  "sectionList": "Settings_sectionList_5e7b5",
  "settingsButton": "Settings_settingsButton_5e7b5",
  "flowVertical": "Settings_flowVertical_5e7b5",
  "statusLabel": "Settings_statusLabel_5e7b5",
  "toggleGroup": "Settings_toggleGroup_5e7b5",
  "colorSchemeOption": "Settings_colorSchemeOption_5e7b5"
};import "ni:sha-256;8AzC98tSRWOV2r27Ftix2zMPhxdjdq8JVcgv5B6GStM";