import { sticky } from "@helpers/sticky";
import { $, $$, RouterStore, type ViewContext } from "@manyducks.co/dolla";
import { NotesStore } from "@stores/NotesStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { CardDetailStack } from "@views/CardDetailStack";
import { Details } from "./Details/Details";
import { List } from "./List/List";
import styles from "./ProjectNotes.module.css";

interface ProjectNotesProps {}

export function ProjectNotes(props: ProjectNotesProps, ctx: ViewContext) {
  ctx.name = "ProjectNotes";

  const router = ctx.getStore(RouterStore);
  const notes = ctx.getStore(NotesStore);
  const projects = ctx.getStore(ProjectsStore);

  const $projectId = $(router.$params, (p) => (p.projectId ? Number(p.projectId) : null));
  const $currentProject = $($projectId, projects.$cache, (id, projects) => projects.find((p) => p.id === id));
  const $latestProject = $($currentProject, sticky());

  const $currentNote = $(router.$params, notes.$cache, (params, notes) =>
    params.noteId ? notes.get(Number(params.noteId)) : undefined,
  );
  const $$latestNote = $$($currentNote.get());

  ctx.observe($currentNote, $projectId, $latestProject, (note, projectId, latestProject) => {
    if (note && note.projectId === projectId /* && $$latestNote.get()?.id !== note.id */) {
      $$latestNote.set(note);
    } else if (projectId != latestProject?.id) {
      $$latestNote.set(undefined);
    }
  });

  ctx.observe($projectId, (projectId) => {
    if (projectId != null) {
      notes.fetchIndexFor(projectId);
    }
  });

  return (
    <div class={styles.layout}>
      <CardDetailStack
        showDetail={$($currentNote, (note) => note != null)}
        listContent={<List />}
        detailContent={<Details $project={$latestProject} $note={$($$latestNote)} />}
      />
    </div>
  );
}
