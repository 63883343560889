import { $, LanguageStore, RouterStore, ViewContext } from "@manyducks.co/dolla";
import { ProjectsStore } from "@stores/ProjectsStore";
import { PlaceLayout } from "Workspace/PlaceLayout/PlaceLayout";

export function Project(props: {}, ctx: ViewContext) {
  const { translate } = ctx.getStore(LanguageStore);
  const router = ctx.getStore(RouterStore);
  const projects = ctx.getStore(ProjectsStore);
  const $projectId = $(router.$params, (params) => (params.projectId ? Number(params.projectId) : null));
  const $project = $($projectId, projects.$cache, (id, projects) => projects.find((p) => p.id === id));

  ctx.onConnected(() => {
    ctx.observe($projectId, async (projectId) => {
      if (projectId != null) {
        const { exists } = await projects.ensureProjectLoaded(projectId);
        if (!exists) {
          return router.navigate("/");
        }
      }
    });
  });

  return (
    <PlaceLayout
      color={$($project, (p) => p?.color)}
      title={$($project, (p) => p?.name ?? "???")}
      tabs={[
        {
          href: $($projectId, (id) => `/projects/${id}/notes`),
          active: (pattern) => pattern.startsWith("/projects/{#projectId}/notes"),
          hidden: $($project, (p) => !p?.notesEnabled),
          text: translate("workspace.project.notes.tabName"),
        },
        {
          href: $($projectId, (id) => `/projects/${id}/tasks`),
          active: (pattern) => pattern.startsWith("/projects/{#projectId}/tasks"),
          hidden: $($project, (p) => !p?.tasksEnabled),
          text: translate("workspace.project.tasks.tabName"),
        },
        {
          href: $($projectId, (id) => `/projects/${id}/calendar`),
          active: (pattern) => pattern.startsWith("/projects/{#projectId}/calendar"),
          // hidden: $($project, (p) => !p?.tasksEnabled),
          text: translate("workspace.project.calendar.tabName"),
        },
        {
          href: $($projectId, (id) => `/projects/${id}/people`),
          active: (pattern) => pattern === "/projects/{#projectId}/people",
          text: translate("workspace.project.people.tabName"),
        },
        {
          href: $($projectId, (id) => `/projects/${id}/settings`),
          active: (pattern) => pattern === "/projects/{#projectId}/settings",
          text: translate("workspace.project.settings.tabName"),
        },
      ]}
    >
      {ctx.outlet()}
    </PlaceLayout>
  );
}
