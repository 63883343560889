export default {
  "loader": "DuckLoader_loader_d453e",
  "loaderPane": "DuckLoader_loaderPane_d453e",
  "visible": "DuckLoader_visible_d453e",
  "animating": "DuckLoader_animating_d453e",
  "container": "DuckLoader_container_d453e",
  "outerCircle": "DuckLoader_outerCircle_d453e",
  "cardLoaderOuterSpin": "DuckLoader_cardLoaderOuterSpin_d453e",
  "innerCircle": "DuckLoader_innerCircle_d453e",
  "cardLoaderInnerSpin": "DuckLoader_cardLoaderInnerSpin_d453e",
  "emoji": "DuckLoader_emoji_d453e",
  "cardLoaderDuckFloat": "DuckLoader_cardLoaderDuckFloat_d453e",
  "background": "DuckLoader_background_d453e"
};import "ni:sha-256;OdYHrAnubaqoSLIPrEB-ysxoKnkj1XFL-anRj46aZOw";