export default {
  "container": "Details_container_e9b14",
  "emptyPlaceholder": "Details_emptyPlaceholder_e9b14",
  "loader": "Details_loader_e9b14",
  "show": "Details_show_e9b14",
  "meta": "Details_meta_e9b14",
  "metaGroup": "Details_metaGroup_e9b14",
  "mobileBackButtonGroup": "Details_mobileBackButtonGroup_e9b14",
  "metaButton": "Details_metaButton_e9b14",
  "active": "Details_active_e9b14",
  "open": "Details_open_e9b14",
  "metaButtonIcon": "Details_metaButtonIcon_e9b14",
  "metaButtonLabel": "Details_metaButtonLabel_e9b14",
  "metaDivider": "Details_metaDivider_e9b14",
  "metaContent": "Details_metaContent_e9b14",
  "metaSection": "Details_metaSection_e9b14",
  "metaSubSection": "Details_metaSubSection_e9b14",
  "metaAttachmentsList": "Details_metaAttachmentsList_e9b14",
  "metaAttachmentUpload": "Details_metaAttachmentUpload_e9b14",
  "metaAttachmentUploadInput": "Details_metaAttachmentUploadInput_e9b14",
  "metaAttachmentUploadLabel": "Details_metaAttachmentUploadLabel_e9b14",
  "outlineList": "Details_outlineList_e9b14",
  "outlineItem": "Details_outlineItem_e9b14",
  "metaCheckbox": "Details_metaCheckbox_e9b14",
  "metaCheckboxLabel": "Details_metaCheckboxLabel_e9b14",
  "timestampList": "Details_timestampList_e9b14",
  "timestampListItem": "Details_timestampListItem_e9b14",
  "timestampListItemMain": "Details_timestampListItemMain_e9b14",
  "timestampListItemSecondary": "Details_timestampListItemSecondary_e9b14",
  "content": "Details_content_e9b14"
};import "ni:sha-256;K44V2nKaolU13Orb_ckjucexdtJQ8kRtvKzDAofOZ5s";