import { sticky } from "@helpers/sticky";
import { $, $$, HTTPStore, StoreContext } from "@manyducks.co/dolla";
import { Favorite, File } from "schemas";
import { BreakpointStore } from "./BreakpointStore";

export function NavStore(ctx: StoreContext) {
  const http = ctx.getStore(HTTPStore);
  const breakpoint = ctx.getStore(BreakpointStore);

  /*=================================*\
  |*            Favorites            *|
  \*=================================*/

  const $$favorites = $$<Favorite[]>([]);

  async function fetchFavorites() {
    const res = await http.get<Favorite[]>("/api/favorites");
    $$favorites.set(res.body);
  }

  async function addFavorite(path: string) {
    let highestOrder = 0;
    for (const favorite of $$favorites.get()) {
      if (favorite.order > highestOrder) {
        highestOrder = favorite.order;
      }
    }
    const res = await http.post<Favorite>("/api/favorites", { body: { path } });
    $$favorites.update((current) => [...current, res.body]);
  }

  async function removeFavorite(id: number) {
    $$favorites.update((current) => current.filter((f) => f.id !== id));

    await http.delete(`/api/favorites/${id}`);
  }

  async function reorderFavorites(favorites: Favorite[]) {
    // TODO: Implement reordering
    localStorage.setItem("favorites", JSON.stringify($$favorites.get()));
  }

  /*=================================*\
  |*           File Viewer           *|
  \*=================================*/

  const $$fileViewerIsOpen = $$(false);
  const $$fileViewerContent = $$<File>();

  function openFileViewer(file: File) {
    $$fileViewerContent.set(file);
    $$fileViewerIsOpen.set(true);
  }

  function closeFileViewer() {
    $$fileViewerIsOpen.set(false);
  }

  /*=================================*\
  |*             NavCards            *|
  \*=================================*/

  type NavCard = "places" | "search" | "settings" | "chat";

  const $$leftNavItems = $$(["places", "search", "settings"]);
  const $$rightNavItems = $$(["chat"]);

  const $$leftNavCard = $$<NavCard | null>(localStorage.getItem("nav.leftNavCard") as NavCard);
  const $$rightNavCard = $$<NavCard | null>(localStorage.getItem("nav.rightNavCard") as NavCard);

  const $latestLeftNavCard = $(
    $$leftNavCard,
    sticky((x) => (typeof x === "string" ? x : undefined)),
  );
  const $latestRightNavCard = $(
    $$leftNavCard,
    sticky((x) => (typeof x === "string" ? x : undefined)),
  );

  const $$leftNavIsPersistent = $$<boolean>(localStorage.getItem("nav.leftNavIsPersistent") === "true");
  const $$rightNavIsPersistent = $$<boolean>(localStorage.getItem("nav.rightNavIsPersistent") === "true");

  function closeLeftNavCard() {
    $$leftNavCard.set(null);
    $$leftNavIsPersistent.set(false);
  }

  function closeRightNavCard() {
    $$rightNavCard.set(null);
    $$rightNavIsPersistent.set(false);
  }

  function closeNavCards() {
    closeLeftNavCard();
    closeRightNavCard();
  }

  function openNavCard(value: NavCard, persistent = false) {
    const width = breakpoint.$width.get();

    if ($$leftNavItems.get().includes(value)) {
      $$leftNavCard.set(value);
      $$leftNavIsPersistent.set(persistent);

      if (width < 800) {
        // Close opposite card in mobile view.
        closeRightNavCard();
      } else if (persistent && width < 1200) {
        // Only one side can be persistent until 1200px.
        closeRightNavCard();
      }
    } else if ($$rightNavItems.get().includes(value)) {
      $$rightNavCard.set(value);
      $$rightNavIsPersistent.set(persistent);

      if (width < 800) {
        // Close opposite card in mobile view.
        closeLeftNavCard();
      } else if (persistent && width < 1200) {
        // Only one side can be persistent until 1200px.
        closeLeftNavCard();
      }
    } else {
      throw new Error(`Can't open unknown nav card: ${value}`);
    }
  }

  function closeNavCard(value: NavCard) {
    if ($$leftNavCard.get() === value) {
      closeLeftNavCard();
    }
    if ($$rightNavCard.get() === value) {
      closeRightNavCard();
    }
  }

  function toggleNavCard(value: NavCard, persistent = false) {
    if ($$leftNavItems.get().includes(value)) {
      if ($$leftNavCard.get() === value) {
        // Promote from overlay to persistent.
        if (persistent && !$$leftNavIsPersistent.get()) {
          openNavCard(value, persistent);
        } else {
          closeLeftNavCard();
        }
      } else {
        openNavCard(value, persistent);
      }
    } else if ($$rightNavItems.get().includes(value)) {
      if ($$rightNavCard.get() === value) {
        // Promote from overlay to persistent.
        if (persistent && !$$rightNavIsPersistent.get()) {
          openNavCard(value, persistent);
        } else {
          closeRightNavCard();
        }
      } else {
        openNavCard(value, persistent);
      }
    } else {
      throw new Error(`Can't toggle unknown nav card: ${value}`);
    }
  }

  ctx.observe($$leftNavCard, (value) => {
    if (value) {
      localStorage.setItem("nav.leftNavCard", value);
    } else {
      localStorage.removeItem("nav.leftNavCard");
    }
  });

  ctx.observe($$leftNavIsPersistent, (value) => {
    localStorage.setItem("nav.leftNavIsPersistent", value ? "true" : "false");
  });

  ctx.observe($$rightNavCard, (value) => {
    if (value) {
      localStorage.setItem("nav.rightNavCard", value);
    } else {
      localStorage.removeItem("nav.rightNavCard");
    }
  });

  ctx.observe($$rightNavIsPersistent, (value) => {
    localStorage.setItem("nav.rightNavIsPersistent", value ? "true" : "false");
  });

  return {
    $favorites: $($$favorites),
    fetchFavorites,
    addFavorite,
    removeFavorite,
    reorderFavorites,

    $fileViewerIsOpen: $($$fileViewerIsOpen, $$fileViewerContent, (open, content) => open && content != null),
    $fileViewerContent: $($$fileViewerContent),
    openFileViewer,
    closeFileViewer,

    $leftNavIsOpen: $($$leftNavCard, (value) => value != null),
    $leftNavCard: $($$leftNavCard),
    $leftNavIsPersistent: $($$leftNavIsPersistent),
    $rightNavIsOpen: $($$rightNavCard, (value) => value != null),
    $rightNavCard: $($$rightNavCard),
    $rightNavIsPersistent: $($$rightNavIsPersistent),
    $latestLeftNavCard,
    $latestRightNavCard,
    closeNavCards,
    closeLeftNavCard,
    closeRightNavCard,
    openNavCard,
    closeNavCard,
    toggleNavCard,

    $placeMenuIsOpen: $(
      $$leftNavCard,
      $$rightNavCard,
      (left, right) => left === "places" || right === "places",
    ),
    $searchIsOpen: $($$leftNavCard, $$rightNavCard, (left, right) => left === "search" || right === "search"),
    $settingsIsOpen: $(
      $$leftNavCard,
      $$rightNavCard,
      (left, right) => left === "settings" || right === "settings",
    ),
    $chatIsOpen: $($$leftNavCard, $$rightNavCard, (left, right) => left === "chat" || right === "chat"),
  };
}
