export default {
  "calendar": "CalendarView_calendar_fe8cf",
  "header": "CalendarView_header_fe8cf",
  "days": "CalendarView_days_fe8cf",
  "day": "CalendarView_day_fe8cf",
  "notInMonth": "CalendarView_notInMonth_fe8cf",
  "isToday": "CalendarView_isToday_fe8cf",
  "isSelected": "CalendarView_isSelected_fe8cf",
  "dayButton": "CalendarView_dayButton_fe8cf",
  "dayHeader": "CalendarView_dayHeader_fe8cf",
  "dayMonthLabel": "CalendarView_dayMonthLabel_fe8cf",
  "dayItems": "CalendarView_dayItems_fe8cf",
  "dayItem": "CalendarView_dayItem_fe8cf",
  "completed": "CalendarView_completed_fe8cf",
  "hiddenMessage": "CalendarView_hiddenMessage_fe8cf",
  "daySection": "CalendarView_daySection_fe8cf",
  "empty": "CalendarView_empty_fe8cf"
};import "ni:sha-256;yzFYoK-hlj9Z0gIhAZBpIYmWBm3DdTsDkg6by_-xit4";