export default {
  "taskListItem": "TaskListItem_taskListItem_21c78",
  "itemDivider": "TaskListItem_itemDivider_21c78",
  "task": "TaskListItem_task_21c78",
  "focused": "TaskListItem_focused_21c78",
  "selected": "TaskListItem_selected_21c78",
  "highlighted": "TaskListItem_highlighted_21c78",
  "highlightTask": "TaskListItem_highlightTask_21c78",
  "taskTitle": "TaskListItem_taskTitle_21c78",
  "charmsBar": "TaskListItem_charmsBar_21c78",
  "taskLayout": "TaskListItem_taskLayout_21c78",
  "taskContent": "TaskListItem_taskContent_21c78",
  "taskCheckbox": "TaskListItem_taskCheckbox_21c78",
  "taskDescription": "TaskListItem_taskDescription_21c78",
  "untitled": "TaskListItem_untitled_21c78",
  "taskTitleText": "TaskListItem_taskTitleText_21c78",
  "priorityBadge": "TaskListItem_priorityBadge_21c78",
  "taskTagList": "TaskListItem_taskTagList_21c78",
  "taskTag": "TaskListItem_taskTag_21c78",
  "taskQuickOptions": "TaskListItem_taskQuickOptions_21c78",
  "taskAssignedUser": "TaskListItem_taskAssignedUser_21c78",
  "interactive": "TaskListItem_interactive_21c78",
  "menuOpen": "TaskListItem_menuOpen_21c78",
  "taskDueDate": "TaskListItem_taskDueDate_21c78",
  "inPast": "TaskListItem_inPast_21c78",
  "taskAttachments": "TaskListItem_taskAttachments_21c78",
  "metaSection": "TaskListItem_metaSection_21c78",
  "timestampSection": "TaskListItem_timestampSection_21c78",
  "metaIcon": "TaskListItem_metaIcon_21c78",
  "taskStatus": "TaskListItem_taskStatus_21c78",
  "taskTimestamp": "TaskListItem_taskTimestamp_21c78",
  "assignMenu": "TaskListItem_assignMenu_21c78",
  "editButtonBar": "TaskListItem_editButtonBar_21c78",
  "taskMenu": "TaskListItem_taskMenu_21c78"
};import "ni:sha-256;YEbDhu9gum71RJSlB51_dZkMrlKY1vCrtowwGnTL1mg";