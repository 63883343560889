interface FileDownloadOptions {
  path: string;
  name?: string;
}

/**
 * Asks the user to pick a file. Returns a promise that resolves to an array of the chosen files.
 */
export async function downloadFile(options: FileDownloadOptions): Promise<void> {
  return new Promise<void>((resolve) => {
    const el = document.createElement("a");

    el.href = options.path;
    el.rel = "noopener";

    if (options.name != null) {
      el.download = options.name;
    } else {
      el.download = "";
    }

    el.style.position = "absolute";
    el.style.opacity = "0";
    el.style.pointerEvents = "none";

    document.body.appendChild(el);
    setTimeout(() => {
      el.click();
      document.body.removeChild(el);
      resolve();
    });
  });
}
