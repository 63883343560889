import { $, $$, HTTPStore, LanguageStore, ViewContext, repeat } from "@manyducks.co/dolla";
import { ThemeStore } from "@stores/ThemeStore";
import CloseIcon from "@views/@icons/Close";
import { CollapsibleListSection } from "@views/CollapsibleListSection";
import { MoreMenu } from "@views/MoreMenu";
import { UserAvatar } from "@views/UserAvatar";
import styles from "./People.module.css";
import { CardContent } from "@views/Card";

export function People(props: {}, ctx: ViewContext) {
  const http = ctx.getStore(HTTPStore);
  const theme = ctx.getStore(ThemeStore);
  const { translate } = ctx.getStore(LanguageStore);

  const $$users = $$<any[]>([]);

  ctx.onConnected(async () => {
    const res = await http.get<any[]>("/api/admin/users");
    $$users.set(res.body);

    ctx.log({ users: res.body });
  });

  // TODO: Show server admin status and date created.

  return (
    <CardContent>
      <div class={styles.container}>
        <CollapsibleListSection
          title={translate("workspace.admin.people.headings.active")}
          itemCount={$($$users, (x) => x.length)}
        >
          <ul class={styles.usersList}>
            {repeat(
              $$users,
              (u) => u.id,
              ($user) => {
                const $$menuIsOpen = $$(false);

                return (
                  <li class={styles.listItem} style={theme.getThemeVariables$($($user, (u) => u.color))}>
                    <div class={styles.listItemContent}>
                      <UserAvatar src={$($user, (u) => u.avatar)} />
                      <span class={styles.userName}>{$($user, (u) => u.name)}</span>
                      <span class={styles.userColor}>{$($user, (u) => u.color.toLowerCase())}</span>
                    </div>
                    <MoreMenu
                      $$open={$$menuIsOpen}
                      preferHorizontalAlignment="left"
                      preferVerticalAlignment="below"
                      size="small"
                      color={$($user, (x) => x.color)}
                      options={[
                        {
                          label: translate("workspace.admin.people.menu.deactivate"),
                          icon: <CloseIcon />,
                          disabled: true,
                          callback: () => {
                            // $$linkMenuIsOpen.set(true);
                          },
                        },
                      ]}
                    />
                  </li>
                );
              },
            )}
          </ul>
        </CollapsibleListSection>

        {/* <CollapsibleListSection title={translate("workspace.admin.people.headings.inactive")}}>
        <ul class={styles.usersList}>
          {repeat(
            $$users,
            (u) => u.id,
            ($user) => {
              return (
                <li class={styles.listItem}>
                  <div class={styles.listItemContent}>
                    <UserAvatar src={$($user, (u) => u.avatar)} />
                    <span class={styles.userName}>{$($user, (u) => u.name)}</span>
                  </div>
                </li>
              );
            },
          )}
        </ul>
      </CollapsibleListSection> */}
      </div>
    </CardContent>
  );
}
