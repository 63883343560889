export default {
  "container": "TextEditor_container_12ba4",
  "toolbar": "TextEditor_toolbar_12ba4",
  "hidden": "TextEditor_hidden_12ba4",
  "toolbarGroup": "TextEditor_toolbarGroup_12ba4",
  "toolbarButton": "TextEditor_toolbarButton_12ba4",
  "active": "TextEditor_active_12ba4",
  "toolbarDivider": "TextEditor_toolbarDivider_12ba4",
  "editor": "TextEditor_editor_12ba4",
  "searchMenu": "TextEditor_searchMenu_12ba4",
  "linkBlot": "TextEditor_linkBlot_12ba4",
  "linkBlotIcon": "TextEditor_linkBlotIcon_12ba4",
  "linkBlotTaskStage": "TextEditor_linkBlotTaskStage_12ba4",
  "linkBlotTitle": "TextEditor_linkBlotTitle_12ba4"
};import "ni:sha-256;4BbLjMvXgPa8igfDzPLLSOkCleeusk1d2tRsVsYAamA";