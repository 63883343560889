import { $, Readable, ViewContext, Writable } from "@manyducks.co/dolla";
import styles from "./SearchOrCreateInput.module.css";

interface SearchOrCreateInputProps {
  $$value: Writable<string>;
  $canInteract: Readable<boolean>;
  inputPlaceholder: string | Readable<string>;
  buttonLabel: string | Readable<string>;
  onSubmit: (value: string) => void | Promise<void>;
}

export function SearchOrCreateInput(props: SearchOrCreateInputProps, ctx: ViewContext) {
  return (
    <form
      class={styles.container}
      onSubmit={async (e) => {
        e.preventDefault();

        if (!props.$canInteract.get()) {
          return;
        }

        await props.onSubmit(props.$$value.get());
        props.$$value.set("");
      }}
    >
      <input
        type="text"
        class={styles.searchInput}
        $$value={props.$$value}
        name="noteTitle"
        placeholder={props.inputPlaceholder}
      />

      <input
        type="submit"
        class={styles.searchButton}
        disabled={$(
          props.$$value,
          props.$canInteract,
          (value, interact) => !interact || value.trim().length === 0,
        )}
        value={props.buttonLabel}
      />
    </form>
  );
}
