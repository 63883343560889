import { $, LanguageStore, ViewContext } from "@manyducks.co/dolla";
import { AuthStore } from "@stores/AuthStore";
import { PlaceLayout } from "../PlaceLayout/PlaceLayout";

export function Admin(props: {}, ctx: ViewContext) {
  const { translate } = ctx.getStore(LanguageStore);
  const auth = ctx.getStore(AuthStore);

  return (
    <PlaceLayout
      color={$(auth.$me, (me) => me?.color)}
      title={translate("workspace.nav.admin.label")}
      tabs={[
        {
          href: "/admin/server-stats",
          active: (pattern) => pattern.startsWith("/admin/server-stats"),
          text: translate("workspace.admin.serverStats.tabName"),
        },
        {
          href: "/admin/people",
          active: (pattern) => pattern.startsWith("/admin/people"),
          text: translate("workspace.admin.people.tabName"),
        },
        {
          href: "/admin/projects",
          active: (pattern) => pattern.startsWith("/admin/projects"),
          text: translate("workspace.admin.projects.tabName"),
        },
      ]}
    >
      {ctx.outlet()}
    </PlaceLayout>
  );
}
