export default {
  "container": "ChatEditor_container_2cffe",
  "editor": "ChatEditor_editor_2cffe",
  "placeholder": "ChatEditor_placeholder_2cffe",
  "searchMenu": "ChatEditor_searchMenu_2cffe",
  "attachments": "ChatEditor_attachments_2cffe",
  "inputFormAttachment": "ChatEditor_inputFormAttachment_2cffe",
  "attachmentToolbar": "ChatEditor_attachmentToolbar_2cffe",
  "attachmentMeta": "ChatEditor_attachmentMeta_2cffe",
  "attachmentSizeLabel": "ChatEditor_attachmentSizeLabel_2cffe",
  "attachmentProgressBar": "ChatEditor_attachmentProgressBar_2cffe",
  "form": "ChatEditor_form_2cffe"
};import "ni:sha-256;4hHVUAtXVjR1ST6GIZvlaHI7vigH3VBMgN-0_Z6huCo";