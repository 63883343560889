import { makeDebouncer } from "@helpers/makeDebouncer";
import {
  $,
  $$,
  HTTPStore,
  LanguageStore,
  cond,
  repeat,
  type Readable,
  type ViewContext,
} from "@manyducks.co/dolla";
import { ProjectsStore } from "@stores/ProjectsStore";
import { NoteListItem } from "@views/NoteListItem";
import { SegmentButton } from "@views/SegmentButton";
import { TaskListItem } from "@views/TaskListItem";
import { TextInput } from "@views/TextInput";
import { type Note, type Task } from "schemas";
import { Charm, ProjectCharm, TypeCharm } from "@views/Charms";
import styles from "./Search.module.css";
import { CollapsibleListSection } from "@views/CollapsibleListSection";
import { ThemeStore } from "@stores/ThemeStore";
import { NavStore } from "@stores/NavStore";
import { NavHeader } from "../_NavHeader/NavHeader";

interface SearchProps {
  allowedTypes?: ("notes" | "tasks")[];
  onSelected: (result: SearchResult) => void;
}

interface GenericSearchResult<Type, Schema> {
  rank: number;
  type: Type;
  data: Schema;
}

export type SearchResult = NoteSearchResult | TaskSearchResult;
export type NoteSearchResult = GenericSearchResult<"note", Note>;
export type TaskSearchResult = GenericSearchResult<"task", Task>;

type FilterTabValues = "all" | "notes" | "tasks";

export function Search(props: SearchProps, ctx: ViewContext) {
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);
  const http = ctx.getStore(HTTPStore);
  const projects = ctx.getStore(ProjectsStore);
  const theme = ctx.getStore(ThemeStore);
  const nav = ctx.getStore(NavStore);

  const debouncer = makeDebouncer(300);
  const $$inputValue = $$("");
  const $$searchInput = $$<HTMLInputElement>();

  ctx.onConnected(() => {
    ctx.observe(nav.$searchIsOpen, (open) => {
      if (open) {
        $$searchInput.get()?.focus();
      }
    });
  });

  // TODO: Show results for all types with each type under its own heading.
  // Users and projects should appear in search results, but those searches happen locally.

  const allowedTypes = props.allowedTypes == null ? ["notes", "tasks"] : props.allowedTypes;
  const $$selectedTab = $$<FilterTabValues>("all");
  const $$results = $$<SearchResult[]>([]);

  ctx.observe($$inputValue, $$selectedTab, (value, tab) => {
    if (value.length > 1) {
      const type = tab === "all" ? allowedTypes.join(",") : tab;

      debouncer.queue(() => {
        http
          .get<SearchResult[]>(`/api/search?search=${encodeURIComponent(value)}&type=${type}`)
          .then((res) => {
            ctx.log("received response", res.body);
            $$results.set(res.body);
          });
      });
    }
  });

  const $noteResults = $($$results, (results) =>
    results.filter((r) => r.type === "note").sort((a, b) => b.rank - a.rank),
  );
  const $taskResults = $($$results, (results) =>
    results.filter((r) => r.type === "task").sort((a, b) => b.rank - a.rank),
  );

  ctx.onConnected(() => {
    $$searchInput.get()!.focus();
  });

  return (
    <div
      class={styles.layout}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <NavHeader title={translate("workspace.search.title")} />

      <div class={styles.header}>
        <div class={styles.textInput}>
          <TextInput
            ref={$$searchInput}
            value={$$inputValue}
            placeholder={$($$selectedTab, (t) => translate(`views.searchMenu.searchPlaceholder`))}
            autofocus
          />
        </div>
      </div>

      <ul class={styles.results}>
        {cond(
          $($$results, (r) => r.length === 0),

          <p class={styles.noResults}>{translate("views.searchMenu.emptyListPlaceholder")}</p>,

          <>
            {cond(
              $($noteResults, (r) => r.length > 0),
              <CollapsibleListSection
                title={translate("views.searchMenu.notesHeading")}
                itemCount={$($noteResults, (r) => r.length)}
              >
                <ul class={styles.resultContent}>
                  {repeat(
                    $noteResults,
                    (item) => item.data.id,
                    ($item, $index, ctx) => {
                      return (
                        <NoteListItem
                          interactive={false}
                          $note={$($item, (i) => i.data as Note)}
                          $charms={$(
                            $item as Readable<NoteSearchResult>,
                            projects.$cache,
                            (item, projects) => {
                              const project = projects.find((p) => p.id === item.data.projectId);

                              return (
                                <>
                                  <ProjectCharm project={project} />
                                  {item.data.isPinned ? (
                                    <Charm
                                      icon={"📌"}
                                      label={translate("views.searchMenu.pinnedBadgeLabel")}
                                    />
                                  ) : null}
                                </>
                              );
                            },
                          )}
                          onClick={() => {
                            props.onSelected($item.get());
                          }}
                        />
                      );
                    },
                  )}
                </ul>
              </CollapsibleListSection>,
            )}

            {cond(
              $($taskResults, (r) => r.length > 0),
              <CollapsibleListSection
                title={translate("views.searchMenu.tasksHeading")}
                itemCount={$($taskResults, (r) => r.length)}
              >
                <ul class={styles.resultContent}>
                  {repeat(
                    $taskResults,
                    (item) => item.data.id,
                    ($item, $index, ctx) => {
                      return (
                        <TaskListItem
                          interactive={false}
                          $task={$($item, (i) => i.data as Task)}
                          $charms={$(
                            $item as Readable<TaskSearchResult>,
                            projects.$cache,
                            $currentLanguage,
                            (item, projects) => {
                              const project = projects.find((p) => p.id === item.data.projectId);

                              return (
                                <>
                                  <ProjectCharm project={project} />
                                </>
                              );
                            },
                          )}
                          onClick={() => {
                            props.onSelected($item.get());
                          }}
                        />
                      );
                    },
                  )}
                </ul>
              </CollapsibleListSection>,
            )}
          </>,
        )}
      </ul>
    </div>
  );
}
