import { $, $$, LanguageStore, RouterStore, type ViewContext } from "@manyducks.co/dolla";
import { AuthStore } from "@stores/AuthStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { TasksStore } from "@stores/TasksStore";
import { SegmentButton } from "@views/SegmentButton";
import { Active } from "./Active/Active";
import { Assigned } from "./Assigned/Assigned";
import { Calendar } from "./Calendar/Calendar";
import { History } from "./History/History";
import styles from "./List.module.css";
import { SearchOrCreateInput } from "@views/SearchOrCreateInput";
import { ColorDivider } from "@views/ColorDivider";

type TaskTab = "all" | "assigned";

export interface ListProps {}

export function List(props: ListProps, ctx: ViewContext) {
  ctx.name = "ProjectTasks/List";

  const auth = ctx.getStore(AuthStore);
  const router = ctx.getStore(RouterStore);
  const projects = ctx.getStore(ProjectsStore);
  const tasks = ctx.getStore(TasksStore);
  const { translate } = ctx.getStore(LanguageStore);

  const $projectId = $(router.$params, (params) => (params.projectId ? Number(params.projectId) : 0));

  const $project = $(projects.$cache, $projectId, (projects, id) => projects.find((p) => p.id === id));
  const $userRole = $(auth.$me, $project, (user, project) => {
    if (!user || !project || project.archivedAt != null) {
      return "viewer";
    }
    return project.users.find((u) => u.id === user.id)?.role ?? "viewer";
  });
  const $projectColor = $($project, (project) => project?.color);

  const initialTab = ["all", "assigned"].includes(String(router.$$query.get().view))
    ? (String(router.$$query.get().view) as TaskTab)
    : "all";
  const $$selectedTab = $$<TaskTab>(initialTab);

  const $$filterTags = $$<string[]>([]);
  const $$inputText = $$<string>("");

  ctx.observe($$selectedTab, (value) => {
    router.$$query.update((query) => {
      return {
        ...query,
        view: value,
      };
    });
  });

  const $canInteract = $($userRole, (role) => role !== "viewer");

  ctx.observe($projectId, (projectId) => {
    if (projectId != null && projectId != 0) {
      tasks.fetchIndexFor(projectId);
    }
  });

  return (
    <div class={styles.layout}>
      <div class={styles.searchOrCreate}>
        <SearchOrCreateInput
          $$value={$$inputText}
          $canInteract={$canInteract}
          onSubmit={async (value) => {
            const projectId = $projectId.get();
            const user = auth.$me.get();

            await tasks
              .createTask({
                projectId: projectId,
                assignedUserId: $$selectedTab.get() === "assigned" ? user?.id : undefined,
                delta: [{ insert: value }, { insert: "\n", attributes: { header: 1 } }, { insert: "\n" }],
                tags: $$filterTags.get(),
              })
              .then((task) => {
                setTimeout(() => {
                  // scrollToTask(task.id);
                  tasks.$$highlightTaskId.set(task.id);
                }, 20);
              });
          }}
          inputPlaceholder={translate("workspace.project.tasks.searchOrCreate.inputPlaceholder")}
          buttonLabel={translate("workspace.project.tasks.searchOrCreate.buttonText")}
        />
      </div>

      <div class={styles.segmentButtons}>
        <SegmentButton
          value={$$selectedTab}
          onChange={$$selectedTab.set}
          segments={[
            {
              value: "all",
              content: <div class={styles.segment}>{translate("workspace.project.tasks.all.tabName")}</div>,
            },
            {
              value: "assigned",
              content: (
                <div class={styles.segment}>{translate("workspace.project.tasks.assigned.tabName")}</div>
              ),
            },
            // {
            //   value: "completed",
            //   content: (
            //     <div class={styles.segment}>{translate("workspace.project.tasks.completed.tabName")}</div>
            //   ),
            // },
          ]}
        />
      </div>

      <ColorDivider />

      {$($$selectedTab, (tab) => {
        if (tab === "all") {
          return (
            <Active
              $projectId={$projectId}
              $userRole={$userRole}
              $projectColor={$projectColor}
              $$filterTags={$$filterTags}
            />
          );
        }

        if (tab === "assigned") {
          return (
            <Assigned
              $projectId={$projectId}
              $userRole={$userRole}
              $projectColor={$projectColor}
              $$filterTags={$$filterTags}
            />
          );
        }

        // if (tab === "completed") {
        //   return <History $projectId={$projectId} $userRole={$userRole} />;
        // }
      })}
    </div>
  );
}
