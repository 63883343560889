import {
  $,
  $$,
  cond,
  repeat,
  type Readable,
  type Renderable,
  type ViewContext,
  type Writable,
} from "@manyducks.co/dolla";
import chroma from "chroma-js";
import { adjustAccentColor } from "@helpers/makeTheme";
import { ThemeStore } from "@stores/ThemeStore";
import styles from "./CollapsibleListSection.module.css";

type ControlVariant = "accent" | "normal";

interface CollapsibleListControl {
  id: string | number;
  icon: Renderable;
  title: string | Readable<string>;
  variant?: ControlVariant;
  disabled?: boolean | Readable<boolean>;
  hidden?: boolean | Readable<boolean>;
  onClick: (e: Event) => void;
}

interface CollapsibleListSectionProps {
  $$open?: Writable<boolean>;
  title: string | Readable<string>;
  itemCount?: number | Readable<number>;
  headerColor?: string | Readable<string>;
  controls?: CollapsibleListControl[];
}

export function CollapsibleListSection(props: CollapsibleListSectionProps, ctx: ViewContext) {
  const theme = ctx.getStore(ThemeStore);

  const $$open = $$(props.$$open ?? true);
  const $title = $(props.title);
  const $headerColor = $(props.headerColor);
  const $itemCount = $(props.itemCount);
  const $controls = $(props.controls ?? []);

  const $styles = $($headerColor, theme.$isDark, (color, dark) => {
    if (color) {
      const accent = adjustAccentColor(color, dark);
      return {
        "--color-header-background": dark
          ? chroma(accent).luminance(0.2).hex()
          : chroma(accent).luminance(0.8).hex(),
        "--color-header-background-hover": dark
          ? chroma(accent).luminance(0.24).hex()
          : chroma(accent).luminance(0.76).hex(),
        "--color-header-text": dark ? "#fff" : accent,
      };
    }
  });

  return (
    <section class={styles.collapsibleListSection}>
      <header style={$styles}>
        <button
          class={[
            styles.listSectionHeaderBackground,
            { [styles.open]: $$open, [styles.withControls]: $($controls, (x) => x != null && x.length > 0) },
          ]}
          type="button"
          onClick={() => {
            $$open.update((open) => !open);
            ctx.log("open is now", $$open.get());
          }}
        >
          <span class={styles.listSectionTitle}>
            <span class={[styles.listSectionOpenIndicator, { [styles.open]: $$open }]} />
            {$title}
          </span>
          {cond(
            $($itemCount, (ic) => ic != null),
            <span class={styles.listSectionCount}>{$itemCount}</span>,
          )}
        </button>
        {cond(
          $controls,
          <ul class={styles.controls}>
            {repeat(
              $controls,
              (control) => control.id,
              ($control) => {
                const $title = $($control, (c) => c.title);
                const $icon = $($control, (c) => c.icon);
                const $disabled = $($control, (c) => c.hidden ?? false);
                const $hidden = $($control, (c) => c.hidden ?? false);

                return (
                  <>
                    {cond(
                      $hidden,
                      null,
                      <li>
                        <button
                          class={[
                            styles.controlButton,
                            { [styles.accent]: $($control, (c) => c.variant === "accent") },
                          ]}
                          disabled={$disabled}
                          title={$title}
                          type="button"
                          onClick={(e) => {
                            const { onClick } = $control.get();
                            onClick(e);
                          }}
                        >
                          {$icon}
                        </button>
                      </li>,
                    )}
                  </>
                );
              },
            )}
          </ul>,
        )}
      </header>
      <div class={[styles.listSectionContent, { [styles.show]: $$open }]}>{ctx.outlet()}</div>
    </section>
  );
}
