import { $, $$, cond, Readable, repeat, RouterStore, ViewContext } from "@manyducks.co/dolla";
import styles from "./PlaceLayout.module.css";
import { ThemeStore } from "@stores/ThemeStore";
import { BreakpointStore } from "@stores/BreakpointStore";
import { NavStore } from "@stores/NavStore";
import { sticky } from "@helpers/sticky";
import { AuthStore } from "@stores/AuthStore";
import { ChatStore } from "@stores/ChatStore";
import { NotesStore } from "@stores/NotesStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { TasksStore } from "@stores/TasksStore";
import ArrowRight from "@views/@icons/ArrowRight";
import Heart from "@views/@icons/Heart";
import HeartLine from "@views/@icons/HeartLine";
import ArrowLeft from "@views/@icons/ArrowLeft";

export interface Tab {
  text: string | Readable<string>;
  href: string | Readable<string>;
  active: (pattern: string) => boolean;
  hidden?: boolean | Readable<boolean>;
}

interface PlaceLayoutProps {
  color?: string | Readable<string> | Readable<string | undefined>;
  title: string | Readable<string>;
  tabs?: Tab[] | Readable<Tab[]>;
}

export function PlaceLayout(props: PlaceLayoutProps, ctx: ViewContext) {
  const auth = ctx.getStore(AuthStore);
  const router = ctx.getStore(RouterStore);
  const nav = ctx.getStore(NavStore);
  const chat = ctx.getStore(ChatStore);
  const projects = ctx.getStore(ProjectsStore);
  const tasks = ctx.getStore(TasksStore);
  const notes = ctx.getStore(NotesStore);
  const theme = ctx.getStore(ThemeStore);
  const breakpoint = ctx.getStore(BreakpointStore);

  const $$contentElement = $$<HTMLDivElement>();
  const $$contentWidth = $$(0);

  ctx.onConnected(() => {
    const element = $$contentElement.get();

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        $$contentWidth.set(entry.contentRect.width);
      }
    });

    observer.observe(element!);

    ctx.onDisconnected(() => {
      observer.disconnect();
    });
  });

  const $withBackButton = $(router.$pattern, $$contentWidth, (pattern, width) => {
    return (
      width < 1200 &&
      pattern != null &&
      (pattern.startsWith("/projects/{#projectId}/tasks/{#taskId}") ||
        pattern.startsWith("/projects/{#projectId}/notes/{#noteId}"))
    );
  });

  const $subtitle = $(
    router.$pattern,
    router.$params,
    tasks.$cache,
    notes.$cache,
    (pattern, params, tasks, notes) => {
      if (pattern != null) {
        if (pattern.startsWith("/projects/{#projectId}/tasks/{#taskId}")) {
          const task = tasks.get(params.taskId as number);
          if (task) {
            return (
              <span>
                <span class={styles.deemphasized}>Tasks/</span>
                {task.title}
              </span>
            );
          }
        }
        if (pattern.startsWith("/projects/{#projectId}/notes/{#noteId}")) {
          const note = notes.get(params.noteId as number);
          if (note) {
            return (
              <span>
                <span class={styles.deemphasized}>Notes/</span>
                {note.title}
              </span>
            );
          }
        }
      }
    },
  );
  const $latestSubtitle = $($subtitle, sticky());

  function goBack() {
    const pattern = router.$pattern.get();

    if (pattern != null) {
      if (pattern.startsWith("/projects/{#projectId}/tasks")) {
        if (pattern.startsWith("/projects/{#projectId}/tasks/{#taskId}")) {
          const { projectId } = router.$params.get();
          return router.navigate(`/projects/${projectId}/tasks`);
        } else {
          const { projectId } = router.$params.get();
          return router.navigate(`/projects/${projectId}`);
        }
      } else if (pattern.startsWith("/projects/{#projectId}/notes")) {
        if (pattern.startsWith("/projects/{#projectId}/notes/{#noteId}")) {
          const { projectId } = router.$params.get();
          return router.navigate(`/projects/${projectId}/notes`);
        } else {
          const { projectId } = router.$params.get();
          return router.navigate(`/projects/${projectId}`);
        }
      }
    }

    // If all else fails, just go back.
    router.back();
  }

  const $menuBadgeNumber = $(auth.$updateAvailable, projects.$invites, (hasUpdate, invites) => {
    let count = invites.length;
    if (hasUpdate) {
      count += 1;
    }
    return count;
  });

  const $currentPageIsFavorite = $(router.$path, nav.$favorites, (path, favorites) =>
    favorites.some((f) => f.path === path),
  );

  return (
    <section class={styles.layout} style={theme.getThemeVariables$(props.color)}>
      <header
        class={[
          styles.header,
          {
            // [styles.tabless]: $($withBackButton, (withBackButton) => props.tabs == null || withBackButton),
            [styles.tabless]: props.tabs == null,
            [styles.withBackButton]: $withBackButton,
          },
        ]}
      >
        <div class={styles.titleArea}>
          <div class={[styles.title, { [styles.withBackButton]: $withBackButton }]}>
            <button
              class={styles.backButton}
              onClick={() => {
                goBack();
              }}
            >
              <ArrowLeft />
            </button>
            <div class={styles.titleContent}>
              {/* <div class={styles.titleIcon}>{$icon}</div> */}
              <div class={styles.titleText}>
                <span class={styles.mainText}>{props.title}</span>
                <span class={styles.subText}>{$latestSubtitle}</span>
              </div>
            </div>
          </div>

          <div class={styles.titleControls}>
            {cond(
              props.tabs,
              <nav class={styles.tabs}>
                <ul>
                  {repeat(
                    props.tabs!,
                    (tab) => $(tab.href).get(),
                    ($tab) => (
                      <li class={{ [styles.hidden]: $($tab, (t) => t.hidden) }}>
                        <a
                          href={$($tab, (t) => t.href)}
                          class={{
                            [styles.active]: $(router.$pattern, $tab, (pattern, tab) =>
                              pattern ? tab.active(pattern) : false,
                            ),
                          }}
                        >
                          {$($tab, (t) => t.text)}
                        </a>
                      </li>
                    ),
                  )}
                </ul>
                <div class={styles.buttons}></div>
              </nav>,
            )}

            <button
              class={[styles.iconButton, styles.vibrant, { [styles.active]: $currentPageIsFavorite }]}
              style={$(theme.getThemeVariables$("#de2d34"), $currentPageIsFavorite, (themeVars, isFave) => {
                if (isFave) {
                  return themeVars;
                } else {
                  return {};
                }
              })}
              type="button"
              onClick={() => {
                if ($currentPageIsFavorite.get()) {
                  const path = router.$path.get();
                  const favorite = nav.$favorites.get().find((f) => f.path === path);
                  if (favorite) {
                    nav.removeFavorite(favorite.id);
                  }
                } else {
                  nav.addFavorite(router.$path.get());
                }
              }}
            >
              {cond($currentPageIsFavorite, <Heart />, <HeartLine />)}
            </button>
          </div>
        </div>

        {cond(
          props.tabs,
          <nav class={styles.nav}>
            <ul>
              {repeat(
                props.tabs!,
                (tab) => $(tab.href).get(),
                ($tab) => (
                  <li class={{ [styles.hidden]: $($tab, (t) => t.hidden) }}>
                    <a
                      href={$($tab, (t) => t.href)}
                      class={{
                        [styles.active]: $(router.$pattern, $tab, (pattern, tab) =>
                          pattern ? tab.active(pattern) : false,
                        ),
                      }}
                    >
                      {$($tab, (t) => t.text)}
                    </a>
                  </li>
                ),
              )}
            </ul>
            <div class={styles.buttons}></div>
          </nav>,
        )}
      </header>

      <div class={styles.content} ref={$$contentElement}>
        {ctx.outlet()}
      </div>
    </section>
  );
}
