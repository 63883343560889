export default {
  "container": "TagFilter_container_879b5",
  "open": "TagFilter_open_879b5",
  "tagList": "TagFilter_tagList_879b5",
  "emptyListPlaceholder": "TagFilter_emptyListPlaceholder_879b5",
  "tag": "TagFilter_tag_879b5",
  "tagName": "TagFilter_tagName_879b5",
  "tagRemoveButton": "TagFilter_tagRemoveButton_879b5",
  "input": "TagFilter_input_879b5",
  "indicatorIcon": "TagFilter_indicatorIcon_879b5",
  "menuLayout": "TagFilter_menuLayout_879b5",
  "menuInput": "TagFilter_menuInput_879b5",
  "menuTagList": "TagFilter_menuTagList_879b5",
  "menuTagButton": "TagFilter_menuTagButton_879b5"
};import "ni:sha-256;o-djRbj-sEk67TpNFBWKGhkELXbVSb3muDfvLJThN-o";