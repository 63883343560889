export default {
  "chat": "Chat_chat_7f247",
  "layout": "Chat_layout_7f247",
  "editor": "Chat_editor_7f247",
  "pushDisabledBanner": "Chat_pushDisabledBanner_7f247",
  "pushDisabledBannerControls": "Chat_pushDisabledBannerControls_7f247",
  "endOfMessages": "Chat_endOfMessages_7f247",
  "chatListSpacer": "Chat_chatListSpacer_7f247",
  "dividerLine": "Chat_dividerLine_7f247",
  "dividerLabel": "Chat_dividerLabel_7f247",
  "chatHeader": "Chat_chatHeader_7f247",
  "chatTitle": "Chat_chatTitle_7f247",
  "project": "Chat_project_7f247",
  "listContent": "Chat_listContent_7f247",
  "chatMessageList": "Chat_chatMessageList_7f247",
  "chatList": "Chat_chatList_7f247",
  "chatListItem": "Chat_chatListItem_7f247",
  "chatListItemIcon": "Chat_chatListItemIcon_7f247",
  "active": "Chat_active_7f247",
  "chatListItemLabel": "Chat_chatListItemLabel_7f247"
};import "ni:sha-256;ilk63xapLyT8r1NkUmdMochJkSWtDi7l_fh0eXLnfrU";