import { $, $$, cond, DialogStore, LanguageStore, type ViewContext } from "@manyducks.co/dolla";
import { AuthStore } from "@stores/AuthStore";
import { PushStore } from "@stores/PushStore";
import { ThemeStore } from "@stores/ThemeStore";
import MoonIcon from "@views/@icons/Moon";
import SunIcon from "@views/@icons/Sun";
import TimeIcon from "@views/@icons/Time";
import { Button } from "@views/Button";
import { CollapsibleListSection } from "@views/CollapsibleListSection";
import { ColorInput } from "@views/ColorInput";
import { PixelEditor, PixelEditorControls } from "@views/PixelEditor";
import { SegmentButton } from "@views/SegmentButton";
import { Select } from "@views/Select";
import { Switch } from "@views/Switch";
import { TextInput } from "@views/TextInput";
import ToolBarGroup from "@views/ToolBar/ToolBarGroup";
import { UserAvatar } from "@views/UserAvatar";
import { NavContent } from "../_NavContent/NavContent";
import { NavHeader } from "../_NavHeader/NavHeader";
import { makeTogglePush } from "./makeTogglePush";
import styles from "./Settings.module.css";

const langLabels: Record<string, string> = {
  en: "English",
  ja: "日本語",
  tok: "toki pona",
};

interface SettingsProps {}

export function Settings(props: SettingsProps, ctx: ViewContext) {
  ctx.name = "me/settings";

  const { $isPushEnabled } = ctx.getStore(PushStore);
  const { translate, $currentLanguage, setLanguage, supportedLanguages } = ctx.getStore(LanguageStore);
  const auth = ctx.getStore(AuthStore);
  const theme = ctx.getStore(ThemeStore);
  const dialog = ctx.getStore(DialogStore);

  const togglePush = makeTogglePush(ctx);

  const $$pixelEditorRef = $$<PixelEditorControls>();

  const $$passwordEditIsOpen = $$(false);
  const $$passwordValue = $$("");
  const $$passwordConfirmValue = $$("");

  const $name = $(auth.$me, (x) => x!.name);
  const $color = $(auth.$me, (x) => x!.color);
  const $status = $(auth.$me, (x) => x!.status);

  const $$inputColor = $$($color.get());
  const $canSaveColor = $($color, $$inputColor, (color, inputColor) => color !== inputColor);

  const $$userMenuIsOpen = $$(false);

  const $$isEditingAvatar = $$(false);
  const $$isEditingName = $$(false);

  const $$profileSectionIsOpen = $$(true);
  const $$appearanceSectionIsOpen = $$(true);
  const $$languageSectionIsOpen = $$(true);
  const $$credentialsSectionIsOpen = $$(true);
  const $$notificationsSectionIsOpen = $$(true);

  return (
    <div class={styles.layout}>
      <NavHeader title={translate("workspace.settings.title")} />

      <NavContent>
        <CollapsibleListSection
          title={translate("workspace.me.settings.profile.title")}
          $$open={$$profileSectionIsOpen}
        >
          <ul class={styles.sectionList}>
            <li>
              <header class={styles.sectionHeader}>
                <label>{translate("workspace.me.settings.profile.nameHeading")}</label>
              </header>

              {cond(
                $$isEditingName,

                <form
                  class={styles.nameForm}
                  onSubmit={(e) => {
                    e.preventDefault();
                    const value = (e.currentTarget as HTMLFormElement).newName.value.trim();
                    if (value.length > 0) {
                      auth.updateMe({ name: value });
                    }
                    $$isEditingName.set(false);
                  }}
                >
                  <TextInput name="newName" placeholder={$name} />
                  <div class={styles.nameFormButtons}>
                    <Button>{translate("common.cancel")}</Button>
                    <Button type="submit">{translate("common.save")}</Button>
                  </div>
                </form>,

                <div class={styles.userName}>
                  <span>{$name}</span>
                  <Button
                    onClick={() => {
                      $$isEditingName.set(true);
                    }}
                  >
                    {translate("common.edit")}
                  </Button>
                </div>,
              )}
            </li>
            <li>
              <header class={styles.sectionHeader}>
                <label>{translate("workspace.me.settings.profile.avatarHeading")}</label>
              </header>

              {cond(
                $$isEditingAvatar,
                <>
                  <div class={styles.editor}>
                    <PixelEditor
                      getInitialData={() => auth.$me.get()?.avatar ?? undefined}
                      $$controlsRef={$$pixelEditorRef}
                    />
                  </div>

                  <div class={styles.editorButtons}>
                    <Button
                      onClick={() => {
                        $$isEditingAvatar.set(false);
                      }}
                    >
                      {translate("common.cancel")}
                    </Button>
                    <Button
                      onClick={() => {
                        const data = $$pixelEditorRef.get()!.getImageData();
                        auth.updateMe({ avatar: data });
                        $$isEditingAvatar.set(false);
                      }}
                    >
                      {translate("common.save")}
                    </Button>
                  </div>
                </>,
                <div class={styles.userAvatar}>
                  <UserAvatar src={$(auth.$me, (u) => u?.avatar ?? "")} />{" "}
                  <Button
                    onClick={() => {
                      $$isEditingAvatar.set(true);
                    }}
                  >
                    {translate("common.edit")}
                  </Button>
                </div>,
              )}
            </li>

            <li>
              <header class={styles.sectionHeader}>
                <label>{translate("workspace.me.settings.profile.colorHeading")}</label>
              </header>
              <div class={styles.userColorInput}>
                <ColorInput value={$$inputColor} />

                <div class={styles.userColorButtons}>
                  <Button
                    disabled={$($canSaveColor, (x) => !x)}
                    onClick={() => {
                      $$inputColor.set($color.get());
                    }}
                  >
                    {translate("common.cancel")}
                  </Button>
                  <Button
                    disabled={$($canSaveColor, (x) => !x)}
                    onClick={() => {
                      auth.updateMe({
                        color: $$inputColor.get(),
                      });
                    }}
                  >
                    {translate("common.save")}
                  </Button>
                </div>
              </div>
            </li>

            {/* <li style={{ display: "flex" }}>
              <div style={{ flex: "1 1 auto" }}>
                <header class={styles.sectionHeader}>
                  <label>{translate("workspace.me.settings.profile.statusHeading")}</label>
                </header>
                <span class={styles.userStatus}>{$status}</span>
              </div>
              <div style={{ display: "flex", flexFlow: "row nowrap", alignItems: "flex-end" }}>
                <Button
                  onClick={() => {
                    dialog.open(UserStatusEdit, {
                      initialValue: $status.get() ?? "",
                      onSave: (value) => {
                        // Update status with API.
                        auth.updateMe({ status: value });
                      },
                    });
                    $$userMenuIsOpen.set(false);
                  }}
                >
                  {translate("common.edit")}
                </Button>
              </div>
            </li> */}
          </ul>
        </CollapsibleListSection>

        <CollapsibleListSection
          title={translate("workspace.me.settings.appearance.title")}
          $$open={$$appearanceSectionIsOpen}
        >
          <ul class={styles.sectionList}>
            <li>
              <header class={styles.sectionHeader}>
                <label>{translate("workspace.me.settings.appearance.themeHeading")}</label>
              </header>
              <SegmentButton
                value={$(theme.$$selectedScheme)}
                onChange={theme.$$selectedScheme.set}
                segments={[
                  {
                    value: theme.ColorSchemes.System,
                    content: (
                      <div class={styles.colorSchemeOption}>
                        <TimeIcon /> {translate("workspace.me.settings.appearance.colorScheme.system")}
                      </div>
                    ),
                  },
                  {
                    value: theme.ColorSchemes.Light,
                    content: (
                      <div class={styles.colorSchemeOption}>
                        <SunIcon /> {translate("workspace.me.settings.appearance.colorScheme.light")}
                      </div>
                    ),
                  },
                  {
                    value: theme.ColorSchemes.Dark,
                    content: (
                      <div class={styles.colorSchemeOption}>
                        <MoonIcon /> {translate("workspace.me.settings.appearance.colorScheme.dark")}
                      </div>
                    ),
                  },
                ]}
              />
            </li>
          </ul>
        </CollapsibleListSection>

        <CollapsibleListSection
          title={translate("workspace.me.settings.language.title")}
          $$open={$$languageSectionIsOpen}
        >
          <ul class={styles.sectionList}>
            <li>
              <header class={styles.sectionHeader}>
                <h3 class={styles.headerTitle}>
                  {translate("workspace.me.settings.language.languageHeading")}
                </h3>
              </header>
              <Select
                value={$currentLanguage}
                onChange={(value) => {
                  const language = value!;
                  setLanguage(language);
                  auth.updateMe({ language });
                }}
                options={supportedLanguages.map((language) => {
                  return {
                    value: language,
                    label: langLabels[language] || language,
                  };
                })}
              />
            </li>
          </ul>
        </CollapsibleListSection>

        {"Notification" in window && (
          <CollapsibleListSection
            title={translate("workspace.me.settings.notifications.title")}
            $$open={$$notificationsSectionIsOpen}
          >
            <ul class={styles.sectionList}>
              <li class={styles.toggleGroup}>
                <label for="settings-push-enabled">
                  {translate("workspace.me.settings.notifications.pushNotificationsLabel")}
                </label>
                <Switch
                  id="settings-push-enabled"
                  $value={$isPushEnabled}
                  onChange={() => {
                    // TODO: Display a loading state while this is happening.
                    if (!$isPushEnabled.get()) {
                      Notification.requestPermission().then(() => {
                        togglePush();
                      });
                    } else {
                      togglePush();
                    }
                  }}
                />
              </li>
            </ul>
          </CollapsibleListSection>
        )}

        <CollapsibleListSection
          title={translate("workspace.me.settings.credentials.title")}
          $$open={$$credentialsSectionIsOpen}
        >
          <ul class={styles.sectionList}>
            <li>
              <header class={styles.sectionHeader}>
                <h3 class={styles.headerTitle}>
                  {translate("workspace.me.settings.credentials.changePasswordHeading")}
                </h3>
              </header>
              <form
                class={styles.form}
                onSubmit={(e) => {
                  e.preventDefault();
                  auth.changePassword($$passwordValue.get());
                  $$passwordEditIsOpen.set(false);
                  $$passwordValue.set("");
                  $$passwordConfirmValue.set("");
                }}
              >
                <div class={styles.formGroup}>
                  <label class={styles.formLabel} for="password-input">
                    {translate("workspace.me.settings.credentials.newPasswordLabel")}
                  </label>
                  <TextInput
                    id="password-input"
                    type="password"
                    value={$$passwordValue}
                    placeholder="**********"
                  />
                </div>
                <div class={styles.formGroup}>
                  <label class={styles.formLabel} for="password-input">
                    {translate("workspace.me.settings.credentials.confirmPasswordLabel")}
                  </label>
                  <TextInput
                    id="password-confirm-input"
                    type="password"
                    value={$$passwordConfirmValue}
                    placeholder="**********"
                  />
                </div>

                <span style={{ color: "var(--color-text-secondary)" }}>
                  {$(
                    $$passwordValue,
                    $$passwordConfirmValue,
                    translate("workspace.me.settings.credentials.passwordNotMatchedMessage"),
                    translate("workspace.me.settings.credentials.passwordMatchedMessage"),
                    (password, confirm, passwordNotMatchedMessage, passwordMatchedMessage) => {
                      if (password === "" && confirm === "") {
                        return "";
                      }
                      if (password !== confirm) {
                        return passwordNotMatchedMessage;
                      }
                      return passwordMatchedMessage;
                    },
                  )}
                </span>

                <div class={styles.formToolBar}>
                  <ToolBarGroup>
                    <Button
                      type="button"
                      disabled={$(
                        $$passwordValue,
                        $$passwordConfirmValue,
                        (pw, pwc) => pw.length < 1 && pwc.length < 1,
                      )}
                      onClick={(e) => {
                        e.preventDefault();
                        $$passwordValue.set("");
                        $$passwordConfirmValue.set("");
                      }}
                    >
                      {translate("common.cancel")}
                    </Button>
                  </ToolBarGroup>

                  <ToolBarGroup>
                    <Button
                      type="submit"
                      disabled={$(
                        $$passwordValue,
                        $$passwordConfirmValue,
                        (pw, pwc) => pw.length < 1 || pwc.length < 1 || pw !== pwc,
                      )}
                    >
                      {translate("common.save")}
                    </Button>
                  </ToolBarGroup>
                </div>
              </form>
            </li>
            <li>
              <Button
                onClick={() => {
                  auth.logOut().catch((err) => console.error(err));
                }}
              >
                {translate("workspace.me.settings.logOutButtonText")}
              </Button>
            </li>
          </ul>
        </CollapsibleListSection>
      </NavContent>
    </div>
  );
}
