export default {
  "container": "Projects_container_8e756",
  "projectsList": "Projects_projectsList_8e756",
  "listItem": "Projects_listItem_8e756",
  "listItemIcon": "Projects_listItemIcon_8e756",
  "listItemContent": "Projects_listItemContent_8e756",
  "listItemContentMain": "Projects_listItemContentMain_8e756",
  "projectName": "Projects_projectName_8e756",
  "listItemMeta": "Projects_listItemMeta_8e756",
  "metaLabel": "Projects_metaLabel_8e756",
  "metaIcon": "Projects_metaIcon_8e756",
  "metaOwnerNameLabel": "Projects_metaOwnerNameLabel_8e756",
  "metaOtherUsersLabel": "Projects_metaOtherUsersLabel_8e756"
};import "ni:sha-256;zBfZsHbGMeVVaMC47plI_TPYgiOKPV8qfEQA6FJNRiQ";